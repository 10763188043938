import { Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'alert-banner',
  template: `
    <div class="alert-banner" *ngIf="showAlert" [ngClass]="type">
      <span
        *ngIf="type === 'error'"
        class="icon-dx-important-outline"
        alt="Error Icon"
      ></span>
      <span
        *ngIf="type === 'info'"
        class="icon-dx-information"
        alt="Information Icon"
      ></span>
      <span
        *ngIf="type === 'warning'"
        class="icon-dx-caution-outline"
        alt="Warning Icon"
      ></span>
      <span
        *ngIf="type === 'success'"
        class="icon-dx-success"
        alt="Success Icon"
      ></span>
      <span
        name="id-error-message-div"
        class="text"
        [ngClass]="{ 'add-right-padding': !dismissable }"
      >
        <ng-content></ng-content>
      </span>
      <button
        class="icon-dx-close"
        *ngIf="dismissable"
        (click)="close()"
        tabindex="0"
        aria-label="close alert"
      ></button>
    </div>
  `,
  styleUrls: ['./alert-banner.component.scss'],
})
export class AlertBannerComponent {
  @Input() type = 'error';
  @Input() dismissable = false;
  @Input() showAlert = true;
  @Output() bannerOpen: Subject<boolean> = new Subject();

  close() {
    this.showAlert = false;
    this.bannerOpen.next(this.showAlert);
  }
}
