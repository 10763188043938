import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppMetrics } from 'src/app/core/models/app-metrics.model';
import { App } from 'src/app/core/models/app.model';
import { Metrics } from 'src/app/core/models/metrics.model';
import { StoreStates } from 'src/app/core/models/store-states.enum';

import { AppsService } from '../../shared/services/apps.service';
import {
  DashboardActionTypes,
  LoadAppIcon,
  LoadAppInfo,
  LoadAppOverviewMetrics,
  LoadApps,
  RegisterApp,
  StoreAppIcon,
  StoreAppInfo,
  StoreAppOverviewMetrics,
  StoreAppOverviewMetricsError,
  StoreApps,
  StoreRegisterApp,
  UpdateEnvironmentsAvailable,
} from './dashboard.actions';
import { DashboardState } from './dashboard.reducer';
import { apps as selectApps } from './dashboard.selectors';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private store: Store<DashboardState>,
    private appsService: AppsService
  ) {}

  getApps$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LoadApps>(DashboardActionTypes.LoadAppsAction),
        mergeMap((action: LoadApps) => this.appsService.getApps()),
        map(apps => {
          return (apps as App[]).map(a => {
            a.allApplicationVersions = (a.allApplicationVersions as any[]).reduce(
              (obj, item) => {
                if (item.environment) {
                  obj[item.environment.toUpperCase()] = item;
                }
                return obj;
              },
              {}
            );
            return a;
          });
        }),
        map(
          apps =>
            new StoreApps({ apps: apps, appsLoading: StoreStates.SUCCESS })
        ),
        catchError(err => {
          // Set error
          return of(
            new StoreApps({ apps: [], appsLoading: StoreStates.ERROR })
          );
        })
      ),
    { dispatch: true }
  );

  updateEnvironmentsAvailable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<StoreApps>(DashboardActionTypes.StoreAppsAction),
        withLatestFrom(this.store.pipe(select(selectApps))),
        map(([action, apps]) => apps),
        map(apps => {
          const environmentsAvailable = {
            it: false,
            sandbox: true,
            production: false,
          };
          apps.forEach(app => {
            if (app.allApplicationVersions['IT']) {
              environmentsAvailable.it = true;
            }
            if (
              app.allApplicationVersions['PRODUCTION'] &&
              app.productionAccessStatus &&
              app.productionAccessStatus.toUpperCase() === 'APPROVED'
            ) {
              environmentsAvailable.production = true;
            }
          });
          return environmentsAvailable;
        }),
        map(
          envAvailable =>
            new UpdateEnvironmentsAvailable({
              itAvailable: envAvailable.it,
              productionAvailable: envAvailable.production,
            })
        ),
        catchError(err => {
          return EMPTY;
        })
      ),
    { dispatch: true }
  );

  getAppIcon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LoadAppIcon>(DashboardActionTypes.LoadAppIconAction),
        mergeMap((action: LoadAppIcon) =>
          this.appsService.getAppIcon(action.payload.appId)
        ),
        map(app => {
          const updatedApp: Update<App> = {
            id: app.appId,
            changes: {
              image: app.image,
            },
          };
          return updatedApp;
        }),
        map(app => new StoreAppIcon({ app }))
      ),
    { dispatch: true }
  );

  getAppInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LoadAppInfo>(DashboardActionTypes.LoadAppInfoAction),
        mergeMap((action: LoadAppInfo) =>
          this.appsService.getAppInfo(action.payload.appId).pipe(
            catchError(() =>
              of({
                allApplicationVersions: [],
                appDetailsLoaded: StoreStates.ERROR,
                appId: action.payload.appId,
              })
            )
          )
        ),
        map(appInfo => {
          let overviewMetrics: AppMetrics[] = appInfo.allApplicationVersions.map(
            a => {
              const m: AppMetrics = { clientId: a.clientId };
              return m;
            }
          );
          let metrics: Metrics[] = appInfo.allApplicationVersions.map(a => {
            const m: AppMetrics = { clientId: a.clientId };
            return m;
          });

          // Filter for the appropriate overview metrics / metrics
          overviewMetrics = overviewMetrics.filter(
            m => m.clientId !== undefined
          );
          metrics = metrics.filter(m => m.clientId !== undefined);

          return { appInfo, overviewMetrics, metrics };
        }),
        map(appObj => {
          const appId = appObj.appInfo.appId;
          delete appObj.appInfo.appId;
          const entities = appObj.appInfo.allApplicationVersions.reduce(
            (obj, item) => {
              if (item.environment) {
                obj[item.environment.toUpperCase()] = item;
              }
              return obj;
            },
            {}
          );
          const app = appObj.appInfo;
          app.appDetailsLoaded = StoreStates.SUCCESS;
          app.appId = appId;
          app.allApplicationVersions = entities;
          return {
            app: app,
            overviewMetrics: appObj.overviewMetrics,
            metrics: appObj.metrics,
          };
        }),
        map(
          appObj =>
            new StoreAppInfo({
              app: appObj.app,
              overviewMetrics: appObj.overviewMetrics,
              metrics: appObj.metrics,
            })
        )
      ),
    { dispatch: true }
  );

  getAppOverviewMetrics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LoadAppOverviewMetrics>(
          DashboardActionTypes.LoadAppOverviewMetricsAction
        ),
        mergeMap((action: LoadAppOverviewMetrics) =>
          this.appsService
            .getAppOverviewMetrics(
              action.payload.appId,
              action.payload.appClientId,
              action.payload.environment,
              action.payload.duration,
              action.payload.frequency,
              action.payload.consumptionDomain,
              action.payload.timezone
            )
            .pipe(
              catchError(err => {
                const overviewMetricsError: Update<AppMetrics> = {
                  id: action.payload.appClientId,
                  changes: {
                    environment: action.payload.environment,
                    updateTime: new Date(),
                    metricsState: StoreStates.ERROR,
                  },
                };
                // Dispatch the error action
                this.store.dispatch(
                  new StoreAppOverviewMetricsError({
                    overviewMetrics: overviewMetricsError,
                  })
                );
                return of(EMPTY);
              })
            )
        ),
        map(metricsObj => {
          // Default to fail
          let metricsState = StoreStates.ERROR;
          // If we didn't get an empty object back, success
          if (metricsObj && Object.keys(metricsObj).length) {
            metricsState = StoreStates.SUCCESS;
          }
          const updatedMetrics: Update<AppMetrics> = {
            id: metricsObj.clientId,
            changes: {
              ...metricsObj.overviewMetrics,
              environment: metricsObj.environment,
              updateTime: new Date(),
              metricsState: metricsState,
            },
          };
          return { updatedMetrics, metricsObj };
        }),
        map(payload => {
          return { updatedMetrics: payload.updatedMetrics };
        }),
        map(
          payload =>
            new StoreAppOverviewMetrics({
              overviewMetrics: payload.updatedMetrics,
            })
        )
      ),
    { dispatch: true }
  );

  registerApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RegisterApp>(DashboardActionTypes.RegisterAppAction),
        mergeMap((action: RegisterApp) =>
          this.appsService.registerApp(
            action.payload.name,
            action.payload.description,
            action.payload.image,
            action.payload.appType
          )
        ),
        map(
          payload =>
            new StoreRegisterApp({
              isSuccess: true,
              appId: payload.appReferenceId,
            })
        ),
        catchError(() => {
          return of(
            new StoreRegisterApp({
              isSuccess: false,
              appId: 'error',
            })
          );
        })
      ),
    { dispatch: true }
  );
}
