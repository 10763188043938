import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'team-member-access-roles',
  templateUrl: 'team-member-access-roles.component.html',
  styleUrls: ['team-member-access-roles.component.scss'],
})
export class TeamMemberAccessRolesComponent {
  constructor() {}

  @Input() data;
  @Output() close: EventEmitter<void> = new EventEmitter();

  closeModal() {
    this.close.emit();
  }
}
