/**
 * This guard is for rerouting based on feature toggles. It's currently used primarily with turning on new SPAs.
 * Two parameters are passed to the guard - featureName and location
 * If the feature defined by featureName is on then the guard will redirect the user to the location passed
 * When run locally this will not redirect to prevent the user being directed to a SPA that is probably not running locally
 */

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { FeatureToggleService } from '../../shared/services/feature-toggle.service';

@Injectable()
export class FeatureToggleChildGuard implements CanActivateChild {
  constructor(private featureToggleService: FeatureToggleService) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const featureName = childRoute.data.feature;
    const location = childRoute.data.location;
    const path = childRoute.params[childRoute.data.childrenPath];
    if (
      featureName == null ||
      location == null ||
      window.location.origin.includes('localhost')
    ) {
      return true;
    }
    if (childRoute.data.whiteList) {
      return this.featureToggleService
        .getFeatureToggle(featureName)
        .pipe(take(1))
        .toPromise()
        .then(toggle => {
          if (toggle) {
            window.location.href = `${location}${path ? path : ''}`;
            return false;
          }
          return true;
        });
    } else {
      return this.featureToggleService
        .getFeatureToggle(featureName)
        .pipe(take(1))
        .toPromise()
        .then(toggle => {
          if (toggle) {
            window.location.href = location;
            return false;
          }
          return true;
        });
    }
  }
}
