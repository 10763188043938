import { Pipe, PipeTransform } from '@angular/core';
import { ConnectedProduct } from 'src/app/core/models/connected-product.model';

@Pipe({
  name: 'removeDependentProducts',
  pure: true,
})
export class RemoveDependentProductsPipe implements PipeTransform {
  transform(products: ConnectedProduct[]): ConnectedProduct[] {
    if (!products) {
      return [];
    }

    // Create a Set of all dependency product ids for quick lookup
    const dependencyProductIds = new Set(
      products
        .filter(prod => prod.dependencyProducts)
        .map(prod => prod.dependencyProducts)
        .reduce((acc, val) => acc.concat(val), [])
    );

    return products.filter(prod => !dependencyProductIds.has(prod.apiId));
  }
}
