import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../../environments/environment';
import { DashboardEffects } from '../modules/dashboard/dashboard.effects';
import * as fromDashboard from '../modules/dashboard/dashboard.reducer';
import { DashboardService } from '../modules/dashboard/dashboard.service';
import { AlertBannerModule } from '../shared/alert-banner/alert-banner.module';
import { PasswordGuidelinesTileModule } from '../shared/password-guidelines-tile/password-guidelines-tile.module';
import { SortPipe } from '../shared/pipes/sort.pipe';
import { SharedModule } from '../shared/shared.module';
import { SlateModule } from '../shared/slate/slate.module';
import { AppComponent } from './app.component';
import { AppEffects } from './app.effects';

import { GlobalAlertsComponent } from './components/global-alerts/global-alerts.component';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { ChangePasswordComponent } from './modal/change-password/change-password.component';
import { GetAccessComponent } from './modal/get-access/get-access.component';
import { ModalContainerComponent } from './modal/modal-container.component';
import { ModalService } from './modal/modal.service';
import { NewLookComponent } from './modal/new-look/new-look.component';
import { OTPContactUsComponent } from './modal/otp-contact-us/otp-contact-us.component';
import { metaReducers, reducers } from './reducers';
import { TooltipComponent } from './tooltip/tooltip.component';
import { UserSettingsEffects } from './user-settings/user-settings.effects';
import { MessageTrackingInterceptor } from './interceptors/message-tracking.interceptor';
import { RotateCredentialComponent } from './modal/rotate-credential/rotate-credential.component';
import { AuthenticationStoreModule } from '../store/authentication/authentication-store.module';
import { AcceptHeaderInterceptor } from './interceptors/accept-header.interceptor';
import { StateService } from './services/state.service';
import { TeamMemberAccessRolesComponent } from './modal/team-member-access-roles/team-member-access-roles.component';
import { FeedbackService } from './services/feedback.service';
import { FeatureToggleGuard } from './guards/featuretoggle.guard';
import { UserFeedbackComponent } from './modal/user-feedback/user-feedback.component';
import { FeatureToggleChildGuard } from './guards/featuretoggle-child.guard';
import { RedirectGuard } from './guards/redirect.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AppComponent,
    canActivate: [RedirectGuard],
    data: { externalUrl: '/home' },
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('../modules/sign-in/sign-in.module').then(m => m.SignInModule),
  },
  {
    path: 'sign-up',
    loadChildren: () =>
      import('../modules/sign-up/sign-up.module').then(m => m.SignUpModule),
  },
  {
    path: 'sign-up-verify',
    loadChildren: () =>
      import('../modules/sign-up-verify/sign-up-verify.module').then(
        m => m.SignUpVerifyModule
      ),
  },
  {
    path: 'password-reset',
    loadChildren: () =>
      import('../modules/password-reset/password-reset.module').then(
        m => m.PasswordResetModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../modules/dashboard/dashboard.module').then(
        m => m.DashboardModule
      ),
    canActivate: [AuthenticatedGuard],
    data: { breadcrumb: 'Dashboard' },
  },
  {
    path: 'documentation',
    loadChildren: () =>
      import('../modules/documentation/documentation.module').then(
        m => m.DocumentationModule
      ),
    data: { breadcrumb: 'Documentation' },
  },
  {
    path: 'resources',
    loadChildren: () =>
      import('../modules/resources/resources.module').then(
        m => m.ResourcesModule
      ),
  },
  {
    path: 'survey',
    loadChildren: () =>
      import('../modules/survey/survey.module').then(m => m.SurveyModule),
  },
  {
    path: 'help',
    loadChildren: () =>
      import('../modules/help/help.module').then(m => m.HelpModule),
  },
  {
    path: 'otp',
    loadChildren: () =>
      import('../modules/one-time-pin/one-time-pin.module').then(
        m => m.OneTimePinModule
      ),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('../shared/shared.module').then(m => m.SharedModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('../shared/shared.module').then(m => m.SharedModule),
  },
  {
    path: 'platform-documentation',
    redirectTo: 'documentation',
    pathMatch: 'full',
  },
  {
    path: 'signed-out',
    loadChildren: () =>
      import('../modules/sign-out/sign-out.module').then(m => m.SignOutModule),
  },
  { path: '', pathMatch: 'full', redirectTo: '' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  declarations: [
    AppComponent,
    TooltipComponent,
    ModalContainerComponent,
    ChangePasswordComponent,
    GetAccessComponent,
    NewLookComponent,
    OTPContactUsComponent,
    GlobalAlertsComponent,
    RotateCredentialComponent,
    TeamMemberAccessRolesComponent,
    UserFeedbackComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([AppEffects, UserSettingsEffects]),
    // This preloads modules asynchronously after the initial module is loaded
    RouterModule.forRoot(routes, {
      // enableTracing: true, // Uncomment for debugging only
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 72],
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forFeature('dashboard', fromDashboard.reducer),
    EffectsModule.forFeature([DashboardEffects]),
    SlateModule,
    AlertBannerModule,
    PasswordGuidelinesTileModule,
    AuthenticationStoreModule,
  ],
  providers: [
    SortPipe,
    AuthenticatedGuard,
    FeatureToggleGuard,
    RedirectGuard,
    FeatureToggleChildGuard,
    ModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MessageTrackingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptHeaderInterceptor,
      multi: true,
    },
    DashboardService,
    StateService,
    FeedbackService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
