import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { timeouts } from '../../../environments/environment';
import { Section } from '../model/content.model';
import { ContentManagementEndpoints as endpoints } from './service-endpoints';

@Injectable()
export class ContentService {
  constructor(private httpClient: HttpClient) {}

  getPublicSectionsContent(
    url: string,
    miscPageName: string
  ): Observable<Section[]> {
    let publicSectionData: Section[];
    url = endpoints.CONTENT_ENGINE_SEARCH + url;
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );
    return this.httpClient.get(url, { headers }).pipe(
      timeout(timeouts.http),
      map(res => (res as any).article),
      map(article => {
        publicSectionData = article[miscPageName].section;

        return publicSectionData;
      })
    );
  }
}
