import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'resource-tile',
  template: `
    <div class="resource-tile">
      <div class="resource-headline" [innerHTML]="name | safehtml"></div>
      <div class="resource-summary" [innerHTML]="summary | safehtml"></div>
      <div class="bottom">
        <div *ngIf="links && links.length" class="links">
          <a
            class="link"
            *ngFor="let link of links"
            (click)="navigateTo(link.path)"
            [innerHTML]="link.primaryText | safehtml"
          >
          </a>
        </div>
        <div *ngIf="buttons && buttons.length" class="buttons">
          <button
            *ngFor="let button of buttons"
            (click)="navigateTo(button.path)"
            [innerHTML]="button.primaryText | safehtml"
          ></button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./resource-tile.component.scss'],
})
export class ResourceTileComponent implements OnInit {
  @Input() name: string;
  @Input() summary: string;
  @Input() links: any[];
  @Input() buttons: any[];

  constructor(private router: Router) {}

  ngOnInit() {}

  public navigateTo(url: string) {
    // Relative, navigate via current site
    if (url.startsWith('/')) {
      this.router.navigateByUrl(url);
    } else {
      // Not relative - open in new window
      window.open(url, '_blank');
    }
  }
}
