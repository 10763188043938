import { Pipe, PipeTransform } from '@angular/core';
import { ConnectedProduct } from 'src/app/core/models/connected-product.model';

@Pipe({
  name: 'removeConnectedProducts',
  pure: true,
})
export class RemoveConnectedProductsPipe implements PipeTransform {
  /**
   * Filters out products that are already connected.
   * @param products Array of products available to be connected.
   * @param connectedProducts Array of products already connected.
   * @returns Array of products not already connected.
   */
  transform(
    products: ConnectedProduct[],
    connectedProducts: ConnectedProduct[] = []
  ): ConnectedProduct[] {
    const connectedProductIds = new Set(
      connectedProducts.map(prod => prod.apiId)
    );
    return products.filter(prod => !connectedProductIds.has(prod.apiId));
  }
}
