import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, pluck, timeout } from 'rxjs/operators';
import { ProductDetail } from '../../core/models/product-detail.model';
import { timeouts } from '../../../environments/environment';
import { PciEnvMapping } from 'src/mappings/pci-env-mapping';
import { ProductEndpoints as endpoints } from './service-endpoints';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private httpClient: HttpClient) {}

  /**
   * If PCI type environment is passed, return the corresponding portal environment.  Else, return the original
   * environment.
   * @param env Environment checking
   */
  public getPortalProductEnv(env: string): string {
    if (env) {
      env = env.toUpperCase();
      // If the environment is a PCI environment, map it to the corresponding portal environment (non-pci)
      if (PciEnvMapping[env]) {
        return PciEnvMapping[env];
      }
      return env;
    } else {
      return '';
    }
  }

  // Get path from url - required for routerlink due to encoding
  public getPath(url: string): string {
    return url.split('?')[0];
  }

  // Get query params from url - required for routerlink due to encoding
  public getQueries(url: string) {
    const retUrl = url.split('?');
    if (retUrl.length > 1) {
      const vars = retUrl[1].split('&');
      const values = {};
      vars.forEach(query => {
        const queryVal = query.split('=');
        values[queryVal[0]] = queryVal[1];
      });
      return values;
    }
    return {};
  }

  /**
   * Gets products from our product mapping table
   * that user has access to connect to or view information for
   */
  public getUserConnectableSandboxProductInformation(): Observable<
    ProductDetail[]
  > {
    return this.httpClient
      .get(endpoints.GET_AVAILABLE_CONNECTABLE_SANDBOX_PRODUCTS, {
        responseType: 'json',
      })
      .pipe(
        timeout(timeouts.http),
        map(resp => {
          return resp['products'] ? resp['products'] : [];
        }),
        map((products: ProductDetail[]) => {
          products = products.filter(prod => prod.isContractConnectable);
          products = this.checkIfMultipleProdVersionExists(products);
          return products;
        })
      );
  }

  /**
   * Gets products from our product mapping table
   * that user has access to connect to or view information for
   */
  public getUserAvailableProductInformation(): Observable<ProductDetail[]> {
    return this.httpClient
      .get(endpoints.GET_AVAILABLE_PRODUCTS, {
        responseType: 'json',
      })
      .pipe(
        timeout(timeouts.http),
        map(resp => {
          return resp['products'] ? resp['products'] : [];
        })
      );
  }

  /*
   * Gets products a user has access to that have documentation
   */
  public getUserAvailableProductsWithDocs(): Observable<ProductDetail[]> {
    return this.httpClient
      .get(endpoints.GET_AVAILABLE_PRODUCTS_WITH_DOCS, {
        responseType: 'json',
      })
      .pipe(
        timeout(timeouts.http),
        map(resp => {
          return resp['products'] ? resp['products'] : [];
        })
      );
  }

  /**
   * Gets products from our product mapping table and RBAC
   * that user has access to connect to
   */
  public getProductInformationById(
    productId: string
  ): Observable<ProductDetail> {
    const endpoint = endpoints.GET_AVAILABLE_PRODUCT_BY_ID.replace(
      '%productId%',
      productId
    );
    return this.httpClient.get(endpoint, { responseType: 'json' }).pipe(
      timeout(timeouts.http),
      map(resp => {
        if (resp['products'] && resp['products'] instanceof Array) {
          if (resp['products'].length === 1) {
            return resp['products'][0] as ProductDetail;
          } else if (resp['products'].length > 1) {
            console.error('More than one product returned for specified id.');
          }
          return;
        }
      })
    );
  }

  /**
   * Gets available partner / codev products from Lifecycle Engine that user has access to connect to
   */
  getAvailablePartnerProducts() {
    return this.httpClient.get(endpoints.GET_AVAILABLE_PARTNER_PRODUCTS).pipe(
      catchError(err => {
        console.error('Failed fetching codev products');
        return of({ products: [] });
      }),
      timeout(timeouts.http),
      pluck('products'),
      map(prods => prods as any),
      map(envAvailableProducts => {
        // Check if the product has multiple versions
        envAvailableProducts = this.checkIfMultipleProdVersionExists(
          envAvailableProducts
        );

        return envAvailableProducts;
      })
    );
  }

  // Checks if multiple versions of the same product exists
  public checkIfMultipleProdVersionExists(envAvailableProducts) {
    const apiVersionMap = new Map();
    envAvailableProducts.forEach(product => {
      const productId = product.productId.split('-')[0];
      if (apiVersionMap.get(productId) !== undefined) {
        apiVersionMap.set(productId, true);
      } else {
        apiVersionMap.set(productId, false);
      }
    });
    envAvailableProducts.map(prod => {
      const prodId = prod.productId.split('-')[0];
      prod.isMajorVersionAvailable = apiVersionMap.get(prodId);
      return prod;
    });
    return envAvailableProducts;
  }
}
