import { Pipe, PipeTransform } from '@angular/core';
import { ConnectedProduct } from 'src/app/core/models/connected-product.model';
import { AnalyticsService } from '../services/analytics.service';

@Pipe({
  name: 'versionProductNames',
})
export class VersionProductNamesPipe implements PipeTransform {
  constructor(private analyticsService: AnalyticsService) {}

  transform(products: ConnectedProduct[]): ConnectedProduct[] {
    if (!products) {
      return [];
    }

    // Create a new array with transformed product names
    return products.map(product => ({
      ...product,
      apiName: this.analyticsService.getProductName(product),
    }));
  }
}
