import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { AuthEngineEndpoints } from '../../shared/services/service-endpoints';

@Injectable()
export class SignUpService {
  constructor(
    private httpClient: HttpClient,
    private analyticsService: AnalyticsService
  ) {}

  createAccount(signUpData: any, token?: string): Observable<any> {
    if (token) {
      signUpData.token = token;
      return this.httpClient
        .post(AuthEngineEndpoints.CREATE_ACCOUNT_WITH_TOKEN, signUpData)
        .pipe(
          map(response => response as any),
          catchError(error => this.handleError(error))
        );
    } else {
      return this.httpClient
        .post(AuthEngineEndpoints.CREATE_ACCOUNT, signUpData)
        .pipe(
          map(response => response as any),
          catchError(error => this.handleError(error))
        );
    }
  }

  forgotPassword(email: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    headers.append('Accept', '*/*');
    return this.httpClient
      .post(
        AuthEngineEndpoints.FORGOT_PASSWORD,
        { email: email },
        { headers: headers }
      )
      .pipe(
        map(response => response as any),
        catchError(error => this.handleError(error))
      );
  }

  resetPassword(resetData: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    headers.append('Accept', '*/*');
    const options = { headers: headers };

    const isEmailExists = resetData.email !== '';
    const payloadData = {
      ...(isEmailExists && { email: resetData.email }),
      password: resetData.password,
      token: resetData.token,
    };
    return this.httpClient
      .post(AuthEngineEndpoints.RESET_PASSWORD, payloadData, options)
      .pipe(
        map(resp => resp as any),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  changePassword(data: any): any {
    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    headers.append('Accept', '*/*');
    let payload = { ...data };
    if (payload.verifyPassword) delete payload.verifyPassword;
    const options = { headers: headers };
    return this.httpClient
      .put(AuthEngineEndpoints.UPDATE_PASSWORD, payload, options)
      .pipe(
        map(resp => resp as any),
        catchError(error => this.handleError(error))
      );
  }

  private handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `${error.error.developerText}`;
    }
    this.analyticsService.pubSubEvent({
      name: `application_submit_failure:${this.constructor.name}:error-${errorMessage}`,
    });

    return throwError({ status: 'ERROR', message: errorMessage });
  }
}
