import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthEngineEndpoints } from '../../shared/services/service-endpoints';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class DashboardService {
  constructor(
    private httpClient: HttpClient,
    private analyticsService: AnalyticsService
  ) {}
  saveUserImage(image: string): any {
    /**
     * For some reason, the request isn't being intercepted.  We have to add the token now.
     */
    const headers: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );
    const options = { headers: headers };
    const data = { image: image };
    return this.httpClient
      .post(AuthEngineEndpoints.USER_PROFILE_PICTURE, data, options)
      .pipe(
        map(resp => resp as any),
        catchError(error => this.handleError(error, 'saveUserImage'))
      );
  }

  getUserImage(): any {
    return this.httpClient.get(AuthEngineEndpoints.USER_PROFILE_PICTURE).pipe(
      map(resp => resp as any),
      catchError(error => this.handleError(error, 'getUserImage'))
    );
  }

  private handleError(error: any, methodName?: string) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.analyticsService.pubSubEvent({
      name: `application_submit_failure:${this.constructor.name}:error-${methodName}-user-portrait:${errorMessage}`,
    });
    return of({ status: 'ERROR', errorMessage: errorMessage });
  }
}
