export class CommonConstants {
  public static PASSWORD_PATTERN_PRE_ESCAPE_CHAR =
    '^((?=.*[A-Za-z])(?=.*[0-9])(?!.*[^A-Za-z0-9-_/\\';
  public static PASSWORD_PATTERN_POST_ESCAPE_CHAR = '\\@$*&!#])).{8,32}$';
  // Special characters: -_./()@$*&!#
  public static PASSWORD_PATTERN =
    '^(?!.*[^A-Za-z\\d\\$\\&!@#*_)(\\-\\.\\/])((?=.*[A-Z])(?=.*[a-z])(?=.*\\d)|(?=.*[A-Z])(?=.*\\d)(?=.*[\\$\\&!@#*_)(\\-\\.\\/])|(?=.*[a-z])(?=.*\\d)(?=.*[\\$\\&!@#*_)(\\-\\.\\/])|(?=.*[A-Z])(?=.*[a-z])(?=.*[\\$\\&!@#*_)(\\-\\.\\/])).{8,64}$';
  public static INVALID_PASSWORD_PATTERN_MESSAGE =
    'Your password does not meet the requirements.';
  public static EMAIL_PATTERN =
    '^([a-zA-Z0-9_\\-\\.\\+]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\\]?)$';
  public static SERVICE_FIELD_VALIDATION_ERROR_MSG_DELIMITER = '###';
  public static PASS_LOCK_ON_EXPIRY_PERIOD = 90;
  public static PASS_EXPIRY_BANNER_START_DAYS = 14;
  public static OTP_DEFAULT_MESSAGE =
    'We noticed something different about this sign in.';
  public static INACTIVITY_ALERT_TIMER = 840000;
  public static INACTIVITY_LOGOUT_TIMER = 60000;
}

export class CommonFunctions {
  // This function checks if the password contains parts of the email/username or firstname or lastname or if it fails the regex pattern
  static checkInvalidPassword(
    firstname: string,
    lastname: string,
    email: string,
    password: string
  ) {
    // Test the password against the passwordPattern
    if (!RegExp(CommonConstants.PASSWORD_PATTERN).test(password)) return true;

    // Have all the variables that need to be checked in one array
    const variables = [firstname.toLowerCase(), lastname.toLowerCase()];

    // Check against email as well, if email value is present
    if (email) {
      // Separate the username and the domain
      const emailAddress = email.toLowerCase().split('@');
      // Seperate the username based on periods
      const username = emailAddress[0].split('.');
      variables.push(...emailAddress, ...username);
    }

    password = password.toLowerCase();
    // Check if the password contains any parts of the email or firstname or lastname
    for (let i = 0; i < variables.length; i++) {
      if (variables[i] && password.includes(variables[i])) return true;
    }

    return false;
  }
}
