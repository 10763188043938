import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../services/analytics.service';
import { RouterHistoryService } from '../../services/router-history.service';

@Component({
  selector: 'error-page',
  template: `
    <section id="error-page">
      <h1 id="error-page-title">Nothing to see here.</h1>
      <div id="error-page-subtitle">
        Either your request has timed out or the URL is invalid.
      </div>
      <div id="error-page-img">
        <img src="assets/svg/errorRobot.svg" alt="Oops Robot Image" />
      </div>
      <div id="error-page-btns">
        <button class="outline" (click)="goHome()">Go to Homepage</button>
      </div>
    </section>
  `,
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  previousUrlViaRouterHistoryService$ = this.routerHistoryService.previousUrl$;

  constructor(
    private analyticsService: AnalyticsService,
    private routerHistoryService: RouterHistoryService
  ) {}

  ngOnInit() {
    const previous = this.previousUrlViaRouterHistoryService$.getValue();
    this.analyticsService.pubSubEvent({
      name: `application_submit_failure:${this.constructor.name}:previous-url-${previous}`,
    });
  }

  public goHome() {
    window.location.href = '/home/';
  }
}
