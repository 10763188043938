import { Injectable } from '@angular/core';

@Injectable()
export class MetricsService {
  // Class vars
  insertDummy = false;

  /**
   * Gets the total calls
   * @param data Data from the HTTP call
   */
  setTotalCalls(data: any) {
    if (data[0].summary.performanceMetrics) {
      return data[0].summary.performanceMetrics.totalCallCount;
    }
    return 0;
  }

  /**
   * Gets the success rate
   * @param data Data from the HTTP call
   */
  getSuccessRate(data: any) {
    if (data[0].summary.performanceMetrics) {
      const successCount = data[0].summary.performanceMetrics.totalSuccessCount;
      const totalCount = data[0].summary.performanceMetrics.totalCallCount;
      return Math.round((successCount / totalCount) * 100);
    }
    return 0;
  }

  /**
   * Gets the average response time
   * @param data Data from the HTTP call
   */
  getAverageResponseTime(data: any) {
    if (data[0].summary.performanceMetrics) {
      return data[0].summary.performanceMetrics
        .averageResponseTimeInMilliSeconds;
    }
    return 0;
  }

  /**
   * Gets an array of Max TPS readings over a period of time
   * @param data Data from the HTTP call
   */
  getMaxTpsArr(data: any, durationLabel: string) {
    // Should deal with hour as well
    let resp = [];

    // If intervals are returned, use them
    if (data && data.length && data[0].intervals.length) {
      for (const interval of data[0].intervals) {
        let durVal;
        if (durationLabel.toUpperCase() !== 'DAY') {
          durVal = this.formatDay(new Date(interval.fromTimestamp));
        } else {
          durVal = this.formatHr(new Date(interval.fromTimestamp));
        }
        let val = 0;
        if (interval.performanceMetrics.maxTps) {
          val = interval.performanceMetrics.maxTps;
        }

        // The entry for the array that will be parsed
        const entry = { x: durVal, data: val };
        resp.push(entry);
      }
    } else {
      // No data was returned from the response, create intervals to display with values of 0
      resp = this.setEmptyDateArrayTimestamp(durationLabel);
    }

    // Return the response
    return resp;
  }

  /**
   * Gets an array of Total Call readings over a period of time
   * @param data Data from the HTTP call
   */
  getTotalCallArr(data: any, durationLabel: string) {
    // Should deal with hour as well
    let resp = [];

    // If intervals are returned, use them
    if (data && data.length && data[0].intervals.length) {
      for (const interval of data[0].intervals) {
        let durVal;
        if (durationLabel.toUpperCase() !== 'DAY') {
          durVal = this.formatDay(new Date(interval.fromTimestamp));
        } else {
          durVal = this.formatHr(new Date(interval.fromTimestamp));
        }
        let val = 0;
        if (interval.performanceMetrics.totalCallCount) {
          val = interval.performanceMetrics.totalCallCount;
        }

        // The entry for the array that will be parsed
        const entry = { x: durVal, data: val };
        resp.push(entry);
      }
    } else {
      // No data was returned from the response, create intervals to display with values of 0
      resp = this.setEmptyDateArrayTimestamp(durationLabel);
    }

    // Return the response
    return resp;
  }

  /**
   * Gets an array of Failed Call readings over a period of time
   * @param data Data from the HTTP call
   */
  getFailedCallArr(data: any, durationLabel: string) {
    // Should deal with hour as well
    let resp = [];

    // If intervals are returned, use them
    if (data && data.length && data[0].intervals.length) {
      for (const interval of data[0].intervals) {
        let durVal;
        if (durationLabel.toUpperCase() !== 'DAY') {
          durVal = this.formatDay(new Date(interval.fromTimestamp));
        } else {
          durVal = this.formatHr(new Date(interval.fromTimestamp));
        }
        let val = 0;
        if (interval.performanceMetrics.totalErrorCount) {
          val = interval.performanceMetrics.totalErrorCount;
        }
        // The entry for the array that will be parsed
        const entry = { x: durVal, data: val };
        resp.push(entry);
      }
    } else {
      // No data was returned from the response, create intervals to display with values of 0
      resp = this.setEmptyDateArrayTimestamp(durationLabel);
    }

    // Return the response
    return resp;
  }

  /**
   * Gets an array of Failed Call readings over a period of time
   * @param data Data from the HTTP call
   */
  getSuccessfulCallArr(data: any, durationLabel: string) {
    // Should deal with hour as well
    let resp = [];
    // If intervals are returned, use them
    if (data && data.length && data[0].intervals.length) {
      for (const interval of data[0].intervals) {
        let durVal;
        if (durationLabel.toUpperCase() !== 'DAY') {
          durVal = this.formatDay(new Date(interval.fromTimestamp));
        } else {
          durVal = this.formatHr(new Date(interval.fromTimestamp));
        }
        let val = 0;
        if (interval.performanceMetrics.totalSuccessCount) {
          val = interval.performanceMetrics.totalSuccessCount;
        }

        // The entry for the array that will be parsed
        const entry = { x: durVal, data: val };
        resp.push(entry);
      }
    } else {
      // No data was returned from the response, create intervals to display with values of 0
      resp = this.setEmptyDateArrayTimestamp(durationLabel);
    }

    // Return the response
    return resp;
  }

  /**
   * Gets an array of Response Time readings over a period of time
   * @param data Data from the HTTP call
   */
  getMaxResponseTimeArr(data: any, durationLabel: string): any[] {
    // Should deal with hour as well
    let resp = [];
    // If intervals are returned, use them
    if (data && data.length && data[0].intervals.length) {
      for (const interval of data[0].intervals) {
        let durVal;
        if (durationLabel.toUpperCase() !== 'DAY') {
          durVal = this.formatDay(new Date(interval.fromTimestamp));
        } else {
          durVal = this.formatHr(new Date(interval.fromTimestamp));
        }
        let val = 0;
        if (interval.performanceMetrics.maxResponseTimeInMilliSeconds) {
          val = interval.performanceMetrics.maxResponseTimeInMilliSeconds;
        }

        // The entry for the array that will be parsed
        const entry = { x: durVal, data: val };
        resp.push(entry);
      }
    } else {
      // No data was returned from the response, create intervals to display with values of 0
      resp = this.setEmptyDateArrayTimestamp(durationLabel);
    }

    //Return the response
    return resp;
  }

  /**
   * Gets an array of Response Time readings over a period of time
   * @param data Data from the HTTP call
   */
  getAvgResponseTimeArr(data: any, durationLabel: string): any[] {
    // Should deal with hour as well
    let resp = [];
    // If intervals are returned, use them
    if (data && data.length && data[0].intervals.length) {
      for (const interval of data[0].intervals) {
        let durVal;
        if (durationLabel.toUpperCase() !== 'DAY') {
          durVal = this.formatDay(new Date(interval.fromTimestamp));
        } else {
          durVal = this.formatHr(new Date(interval.fromTimestamp));
        }
        let val = 0;
        if (interval.performanceMetrics.averageResponseTimeInMilliSeconds) {
          val = interval.performanceMetrics.averageResponseTimeInMilliSeconds;
        }

        // The entry for the array that will be parsed
        const entry = { x: durVal, data: val };
        resp.push(entry);
      }
    } else {
      // No data was returned from the response, create intervals to display with values of 0
      resp = this.setEmptyDateArrayTimestamp(durationLabel);
    }

    //Return the response
    return resp;
  }

  /**
   * Sets the metric values if no data is provided.  If any data is provided from the API,
   * there will be intervals set.  If none - it will be an empty interval.  We are dealing with the empty
   * interval array here.
   * @param duration String of either 'MONTH', 'DAY', 'WEEK'
   */
  public setEmptyDateArrayTimestamp(durationLabel: string): any {
    let fromTimestamp;
    let resp;
    switch (durationLabel.toUpperCase()) {
      case 'DAY': {
        // Start date should be a day ago
        fromTimestamp = this.getNewDate(-1, 0, 0);
        resp = this.getHourReadingsSince(fromTimestamp);
        break;
      }
      case 'WEEK': {
        // Start date should be a week / 7 days ago
        fromTimestamp = this.getNewDate(-7, 0, 0);
        resp = this.getDayReadingsSince(fromTimestamp);
        break;
      }
      case 'MONTH': {
        // Start date should be a month ago
        fromTimestamp = this.getNewDate(0, -1, 0);
        resp = this.getDayReadingsSince(fromTimestamp);
        break;
      }
      default: {
        // Default duration of a day
        fromTimestamp = this.getNewDate(-1, 0, 0);
        resp = this.getHourReadingsSince(fromTimestamp);
        break;
      }
    }
    return resp;
  }

  // Gets the day values since a date
  public getHourReadingsSince(fromDate: Date): any {
    // Response
    let resp = [];

    // Get hours - previous implementation was flawed - using
    const now = new Date();
    // Get the overall difference in time (ms)
    const diffTime = Math.abs(fromDate.getTime() - now.getTime());
    // Divide by hour to get hours
    const diffHrs = Math.ceil(diffTime / 3600000);
    // Set the start date equal to the originally passed date
    let start = new Date(fromDate);
    // Add hours to the start date to calculate the end date
    const end = new Date(fromDate.setHours(fromDate.getHours() + diffHrs));
    // While the start time isn't equal to the end time, add an hour
    while (start.getTime() !== end.getTime()) {
      // Get hours - this accounts for the first value so we don't miss it
      let val = 0;
      if (this.insertDummy) {
        val = this.getDummyDatapoint();
      }
      const entry = { x: this.formatHr(start), data: val };
      // Increase by an hour
      start = new Date(start.setHours(start.getHours() + 1));

      // Push the the response array
      resp.push(entry);
    }

    // return the response
    return resp;
  }

  public getDayReadingsSince(fromDate: Date): any {
    // Response
    let resp = [];

    // Get hours
    const now = new Date();

    // While we haven't gone a full day cycle
    while (
      fromDate.getDate() <= now.getDate() ||
      fromDate.getMonth() !== now.getMonth()
    ) {
      // Get day - this accounts for the first value so we don't miss it
      let val = 0;
      if (this.insertDummy) {
        val = this.getDummyDatapoint();
      }
      const entry = { x: this.formatDay(fromDate), data: val };
      // Increase by a day
      fromDate = this.addDays(fromDate, 1);
      // Push the the response array
      resp.push(entry);
    }

    // return the response
    return resp;
  }

  public formatHr(date) {
    let hours = date.getHours();
    const unit = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    if (hours === 0) {
      hours = 12;
    }
    const strTime = hours + unit;
    return strTime;
  }

  public formatDay(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1; // Month is 0 indexed
    let strDay = month + '/' + day;
    return strDay;
  }

  public addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  public getDummyDatapoint() {
    return Math.floor(Math.random() * 20) + 1;
  }

  /**
   * Sets the current date and returns a new date
   */
  public getNewDate(days: number, months: number, years: number): Date {
    // Get new date
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + days);
    newDate.setMonth(newDate.getMonth() + months);
    newDate.setFullYear(newDate.getFullYear() + years);
    return newDate;
  }
}
