import { Pipe, PipeTransform } from '@angular/core';

function get<ObjectType>(object: ObjectType, path: string) {
  const keys = path.split('.');
  let result = object;
  for (const key of keys) {
    result = result[key];
  }
  return result;
}

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform<T>(objects: T[], searchText: string, keyToSearch?: any): T[] {
    if (!searchText || !objects) {
      return objects;
    }

    // Create a regex for case-insensitive search
    const searchRegex = new RegExp(searchText, 'i');

    if (keyToSearch) {
      return objects.filter(obj => {
        const value = get(obj, keyToSearch);
        return value && searchRegex.test(value.toString());
      });
    } else {
      return objects.filter(obj =>
        Object.values(obj).some(value => {
          return searchRegex.test(value.toString());
        })
      );
    }
  }
}
