// File containing all external endpoints utilized by various services

/** Endpoints used by  the apps.service */
export const AppsEndpoints = {
  GET_APP_ENV_CONNECTED_PRODS:
    '/protected/23988/devex-engine/apps/%appReferenceId%/contracts',
  CREATE_ENV_CONNECTED_PRODS:
    '/protected/23988/devex-engine/apps/%appReferenceId%/contracts',
  DELETE_ENV_CONNECTED_PRODS:
    '/protected/23988/devex-engine/apps/%appReferenceId%/contracts/%apiReferenceId%',
  GET_APP_ICON: '/protected/23988/devex-engine/apps/%appReferenceId%/image',
  GET_APP_METRICS: '/protected/23988/third-party-engine/performance/analytics',
  GET_APPS: '/protected/23988/devex-engine/apps',
  MODIFY_APP: '/protected/23988/devex-engine/apps/%appReferenceId%',
  TEAM_MEMBERS:
    '/protected/23988/devex-engine/apps/%appReferenceId%/team-member',
  UPDATE_TEAM_MEMBER:
    '/protected/23988/devex-engine/apps/%appReferenceId%/team-member/%teamMemberEmail%',
  DELETE_TEAM_MEMBER:
    '/protected/23988/devex-engine/apps/%appReferenceId%/team-member/%teamMemberEmail%',
  PRODUCTION_ACCESS:
    '/protected/23988/devex-engine/apps/%appReferenceId%/prod-access',
  PRODUCTION_ACCESS_STATUS:
    '/protected/23988/devex-engine/apps/%appReferenceId%/prod-access/status',
  CODEV_FILE: '/protected/23988/third-party-engine/codev/document',
};

export const ProductEndpoints = {
  GET_AVAILABLE_PRODUCTS: '/protected/23988/devex-engine/products/access',
  GET_AVAILABLE_CONNECTABLE_SANDBOX_PRODUCTS:
    '/protected/23988/devex-engine/products/access?isContractConnectable=true&isSandboxProduct=true',
  GET_AVAILABLE_PRODUCT_BY_ID:
    '/protected/23988/devex-engine/products/access?productId=%productId%',
  GET_AVAILABLE_PARTNER_PRODUCTS:
    '/protected/23988/devex-engine/products/owner',
  GET_AVAILABLE_PRODUCTS_WITH_DOCS:
    '/protected/23988/devex-engine/products/access?isDocumentationVisible=true',
};

export const FeatureToggleEndpoints = {
  GET_FEATURE_TOGGLE:
    '/protected/23988/third-party-engine/featuretoggle/health?feature=',
};

export const AuthenticationEndpoints = {
  AUTHID_RETRIEVAL_URL: '/oauth2/authorize',
  AUTH_REDIRECT_URL:
    '%environmentHost%/protected/1085092/auth-engine/devexchange/auth-callback',
  LOGOUT_URL: '/oauth2/oidc/logout',
  LOGOUT_REDIRECT_URL:
    '%environmentHost%/protected/1085092/auth-engine/devexchange/logout-callback',
  SALES_FORCE_JWT_URL:
    '/protected/23988/third-party-engine/gateway/salesforcesupport',
  LOGIN_CALLBACK: '/oauth2/identity/generic/login-callback',
};
export const AuthEngineEndpoints = {
  AUTHENTICATION_URL: '/protected/1085092/auth-engine/token',
  CREATE_ACCOUNT: '/protected/1085092/auth-engine/accounts',
  CREATE_ACCOUNT_WITH_TOKEN:
    '/protected/1085092/auth-engine/accounts/complete-signup',
  FORGOT_PASSWORD: '/protected/1085092/auth-engine/accounts/forgot-password',
  RESET_PASSWORD: '/protected/1085092/auth-engine/accounts/reset-password',
  VERIFY_ACCOUNT: '/protected/1085092/auth-engine/accounts/validate',
  USER_PROFILE_PICTURE: '/protected/1085092/auth-engine/accounts/image',
  USER_OTP_VALIDATE: '/protected/1085092/auth-engine/opt/validate',
  USER_OTP_GENERATE: '/protected/1085092/auth-engine/otp/generate',
  UPDATE_PASSWORD: '/protected/1085092/auth-engine/accounts/update-password',
  SSO_ELIGIBILITY_URL: '/protected/1085092/auth-engine/sso/eligibility',
  RETRIEVE_SSO_ENABLED_DOMAIN_LIST:
    '/protected/1085092/auth-engine/sso/domains',
};
export const ContentManagementEndpoints = {
  CONTENT_ENGINE_SEARCH:
    '/protected/23988/content-engine/content-management/search',
};

export const SalesforceEndpoints = {
  SALESFORCE_GET_FAQS:
    '/protected/23988/third-party-engine/salesforcesupport/knowledge',
  SALESFORCE_GET_SURVEY:
    '/protected/23988/third-party-engine/salesforcesupport/survey/%surveyId%',
  SALESFORCE_RESOURCE_GET_TICKETS_PATH:
    '/protected/23988/third-party-engine/salesforcesupport/tickets',
  SALESFORCE_RESOURCE_POST_TICKETS_AUTH_PATH:
    '/protected/23988/third-party-engine/salesforcesupport/tickets',
  SALESFORCE_RESOURCE_POST_TICKETS_UNAUTH_PATH:
    '/protected/23988/third-party-engine/salesforcesupport/anonymous/tickets',
  SALESFORCE_RESOURCE_GET_PRODUCTS_PATH:
    '/protected/23988/third-party-engine/salesforcesupport/apis',
  SALESFORCE_RESOURCE_CREATE_LEAD_PATH:
    '/protected/23988/third-party-engine/salesforcesupport/contact-us',
};
