/**
 * This guard is for rerouting based on feature toggles. It's currently used primarily with turning on new SPAs.
 * Two parameters are passed to the guard - featureName and location
 * If the feature defined by featureName is on then the guard will redirect the user to the location passed
 * When run locally this will not redirect to prevent the user being directed to a SPA that is probably not running locally
 */

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { take } from 'rxjs/operators';
import { FeatureToggleService } from '../../shared/services/feature-toggle.service';

@Injectable()
export class FeatureToggleGuard implements CanActivate {
  constructor(private featureToggleService: FeatureToggleService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
    const featureName = route.data.feature;
    const location = route.data.location;

    if (
      featureName == null ||
      location == null ||
      window.location.origin.includes('localhost')
    ) {
      return true;
    }

    return this.featureToggleService
      .getFeatureToggle(featureName)
      .pipe(take(1))
      .toPromise()
      .then(toggle => {
        if (toggle) {
          window.location.href = location;
          return false;
        }
        return true;
      });
  }
}
