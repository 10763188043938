import { Component, Input } from '@angular/core';

@Component({
  selector: 'spinner',
  template: `
    <div class="spinner">
      <div class="loading-spinner" [ngClass]="size.toLowerCase()"></div>
    </div>
  `,
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() size = 'small';
}
