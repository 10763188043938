export class Body {
  content: string;
}

export class Section {
  type: string;
  sectionId: string;
  headline: string;
  subheadline: string;
  media: Media[];
  body: Body[];
  section: Section[];
}

export interface Article {
  title: string;
  intro: string;
  metadata: any;
  section: Section[];
}

export interface ContentResponse {
  article: any;
}

export interface Media {
  pathType: string;
  path: string;
  type: string;
  align: string;
  altText: string;
  link: { path: string; url: string };
}

export interface NavItem {
  itemText?: string;
  itemUrl?: string;
  itemId?: string;
  toLevel?: string;
  children?: NavItem[];
}
