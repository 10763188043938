import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  CommonConstants,
  CommonFunctions,
} from 'src/app/core/common.constants';
import { DashboardState } from 'src/app/modules/dashboard/dashboard.reducer';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { SignUpService } from 'src/app/shared/services/sign-up.service';
import { selectUser } from 'src/app/store/authentication/authentication.selectors';
import { User } from '../../models/user.model';
import { setUnauthenticatedState } from 'src/app/store/authentication/authentication.actions';
import { BrowserStorage } from 'src/app/core/models/browser-storage.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'change-password',
  templateUrl: 'change-password.component.html',
  styleUrls: ['change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Input() data: any;
  constructor(
    private store: Store<DashboardState>,
    private analyticsService: AnalyticsService,
    private signupService: SignUpService,
    private router: Router
  ) {}

  @Output() close: EventEmitter<void> = new EventEmitter();
  @ViewChild('changePasswordForm') changePasswordForm: HTMLFormElement;

  public loading = false;
  public passwordChangeErrorVisible = false;
  public showSuccessScreenFlag = false;
  public overallFormError = false;
  public passwordExpiryPeriod = CommonConstants.PASS_LOCK_ON_EXPIRY_PERIOD;
  public passwordPattern = CommonConstants.PASSWORD_PATTERN;
  public invalidPasswordMessage =
    CommonConstants.INVALID_PASSWORD_PATTERN_MESSAGE;
  public invalidPassword: boolean = false;
  public passwordChangeErrorMessage =
    'There was an error changing your password. Please make sure you filled in all the fields correctly.';

  public model = {
    firstName: '',
    lastName: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    verifyPassword: '',
  };

  private _destroyed: Subject<void> = new Subject();

  ngOnInit() {
    this.store
      .pipe(select(selectUser), takeUntil(this._destroyed))
      .subscribe(
        (user: User) => (
          (this.model.email = user.email),
          (this.model.firstName = user.firstName),
          (this.model.lastName = user.lastName)
        )
      );
  }

  checkInvalidPassword() {
    const { firstName, lastName, email, newPassword } = this.model;
    this.invalidPassword = CommonFunctions.checkInvalidPassword(
      firstName,
      lastName,
      email,
      newPassword
    );
  }

  closeModal() {
    this.close.emit();
  }

  changePassword() {
    this.loading = true;
    // Track password change attempt
    this.analyticsService.pubSubEvent({
      name: 'form_start:change-password-page:change-password-attempt',
    });
    const data = (({
      email,
      currentPassword,
      newPassword,
      verifyPassword,
    }) => ({
      email,
      currentPassword,
      newPassword,
      verifyPassword,
    }))(this.model);
    this.signupService
      .changePassword(data)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (resp: any) => {
          this.analyticsService.pubSubEvent({
            name: 'form_complete:change-password-page:change-password-success',
          });
          /** Local storage */
          localStorage.clear();
          /** Session storage */
          sessionStorage.removeItem(BrowserStorage.STATE);
          sessionStorage.removeItem(BrowserStorage.STATE_NONCE);
          sessionStorage.removeItem('correlationId');
          localStorage.setItem('isReloginReq', 'Yes');
          this.closeModal();
          this.store.dispatch(setUnauthenticatedState());
          this.router.navigate(['/relogin']);
        },
        (error: { message: string }) => {
          this.analyticsService.pubSubEvent({
            name:
              'application_submit_failure:change-password-page:change-password-failure',
          });
          this.passwordChangeErrorVisible = true;
          if (error.message !== undefined) {
            this.passwordChangeErrorMessage = error.message;
          }

          (this.changePasswordForm.form as FormGroup).markAsUntouched();
          this.overallFormError = true;
        }
      );
  }
}
