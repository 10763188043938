import { Component, Input, OnInit } from '@angular/core';
import { SlateLink } from '../slate/slate-link.model';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { selectUser } from '../../store/authentication/authentication.selectors';
import { User } from 'src/app/core/models/user.model';
import { clearSession } from '../../store/authentication/authentication.actions';
import { LogoutAction } from '../../core/models/logout-action.enum';

@Component({
  selector: 'dashboard-slate',
  template: `
    <section class="slate" [attr.index]="0">
      <div class="light">
        <ng-container *ngFor="let link of links">
          <a
            class="link-container-light"
            *ngIf="link.title !== 'username'"
            [href]="link.url"
          >
            <span class="menu-item-image-div"
              ><img class="menu-item-image" src="{{ link.imageUrl }}"
            /></span>
            <span class="menu-item-text-div">{{ link.title }}</span>
          </a>
          <a class="link-container-bb" *ngIf="link.title === 'username'">
            {{ userFullName }}
          </a>
        </ng-container>
      </div>
      <div class="dark" *ngIf="darkLink">
        <a class="link-container" (click)="logout()" tabindex="-1">
          <div class="menu-item-with-image">
            <div class="menu-item-image-div">
              <img
                class="dashboad-menu-image"
                id="user-signout-image"
                src="assets/svg/signOut.svg"
              />
            </div>
            <div class="menu-item-text-div">sign out</div>
          </div>
        </a>
      </div>
    </section>
  `,
  styleUrls: ['dashboard-slate.component.scss'],
})
export class DashboardSlateComponent {
  @Input() links: SlateLink[];
  @Input() darkLink: SlateLink;

  user$: Observable<any>;
  userFullName: any;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.user$ = this.store.pipe(select(selectUser));
    this.user$.subscribe((user: User) => {
      this.userFullName = user.firstName + ' ' + user.lastName;
    });
  }

  public logout() {
    this.store.dispatch(clearSession({ logoutAction: LogoutAction.LOGOUT }));
  }
}
