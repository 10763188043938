<section id="global-alerts">
  <alert-banner
    #alertBanner
    type="warning"
    [dismissable]="true"
    *ngIf="passwordExpiryAlertVisible"
    (bannerOpen)="updateBanner($event)"
  >
    <div class="warn-banner-content">
      <div class="warn-banner-content-message">
        {{ passwordExpirationMessage }}
        <a
          id="idHeaderChangePass"
          role="button"
          (click)="openChangePasswordModal()"
          (keydown.enter)="openChangePasswordModal()"
          tabindex="0"
          >Reset your password now</a
        >
        to prevent being locked out.
      </div>
    </div>
  </alert-banner>
</section>
