import { inject, Injectable } from '@angular/core';
import { CaliperX2Service } from './caliperx2.service';

@Injectable()
export class AnalyticsService {
  private caliperX2Service = inject(CaliperX2Service);
  maxRetries = 5;
  retryDelay = 500;

  public async pubSubPageView() {
    setTimeout(() => {
      this.caliperX2Service.potomacPageView();
    }, 500);
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public async pubSubEvent(eventData: any) {
    this.caliperX2Service.potomacInteraction(JSON.stringify(eventData));
  }

  // needs to move to a different service as this doesn't belong in the analytics service !!!!
  //return product name along with version when major versions exists
  getProductName(product) {
    return product.isMajorVersionAvailable
      ? product.apiName + ' (V' + product.apiId.split('-')[1] + ')'
      : product.apiName;
  }
  //check if major version exists for a product, if exists display the version along with product name
  // needs to move to a different service as this doesn't belong in the analytics service !!!!
  checkIfConnectedProdExists(envConnectedProducts) {
    let apiVersionMap = new Map();
    envConnectedProducts.forEach(product => {
      let apiId = product.apiId.split('-')[0];
      if (apiVersionMap.get(apiId) !== undefined)
        apiVersionMap.set(apiId, true);
      else apiVersionMap.set(apiId, false);
    });
    envConnectedProducts.map(prod => {
      let prodId = prod.apiId.split('-')[0];
      prod.isMajorVersionAvailable = apiVersionMap.get(prodId);
      return prod;
    });
    return envConnectedProducts;
  }
}
