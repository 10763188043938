import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  /**
   *
   * @param objects Array of objects being sorted
   * @param sortType One of the types in the switchcase
   * @param sortValue The object property being sorted on (alphabetical only)
   */
  transform(objects: any[], sortType: string, sortValue: string): any {
    let objectsNew;
    // Don't modify the original array
    if (!objects) {
      return;
    }

    // Don't modify the original array
    objectsNew = objects.slice();

    // Will hold the appropriate values
    let retArr;

    // Sort by appropriate method
    switch (sortType.toUpperCase()) {
      case 'RELEVANCE':
        retArr = objectsNew;
        return retArr;
      case 'ALPHABETICAL':
        retArr = objectsNew.sort((a, b) =>
          a[sortValue].localeCompare(b[sortValue])
        );
        return retArr;
      case 'NUMERIC':
        retArr = objectsNew.sort((a, b) => {
          const numA = parseInt(a[sortValue], 10);
          const numB = parseInt(b[sortValue], 10);
          return numA > numB ? 1 : numB > numA ? -1 : 0;
        });
        return retArr;
      case 'LASTUPDATED':
        retArr = objectsNew.sort(function(a, b): any {
          return b.lastUpdatedDate.getTime() - a.lastUpdatedDate.getTime();
        });
        return retArr;
      case 'CATEGORY':
        retArr = objectsNew.sort((a, b) =>
          a.category.localeCompare(b.category)
        );
        return retArr;
      case 'DATE':
        retArr = objectsNew.sort(
          (a, b) => +new Date(a[sortValue]) - +new Date(b[sortValue])
        );
        return retArr;
      case 'DATE-DECREASING':
        retArr = objectsNew.sort(
          (a, b) => +new Date(b[sortValue]) - +new Date(a[sortValue])
        );
        return retArr;
      default:
        retArr = objectsNew;
        return retArr;
    }
  }
}
