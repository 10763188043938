import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AppMetrics } from 'src/app/core/models/app-metrics.model';

/**
 * Overview metrics
 * @param a entity
 */
export function selectClientIdOverviewMetrics(a: AppMetrics): string {
  return a.clientId;
}

export function sortByClientIdOverviewMetrics(
  a: AppMetrics,
  b: AppMetrics
): number {
  return a.clientId.localeCompare(b.clientId);
}

export const overviewMetricsAdapter: EntityAdapter<AppMetrics> = createEntityAdapter<
  AppMetrics
>({
  selectId: selectClientIdOverviewMetrics,
  sortComparer: sortByClientIdOverviewMetrics,
});

export interface OverviewMetricsState extends EntityState<AppMetrics> {}
