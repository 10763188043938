import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { TooltipComponent } from '../../../app/core/tooltip/tooltip.component';

@Directive({ selector: '[appTooltip]' })
export class TooltipDirective {
  constructor(
    private el: ElementRef,
    private cfr: ComponentFactoryResolver,
    private vcr: ViewContainerRef
  ) {}

  @Input() appTooltip: string;

  @HostListener('mouseenter')
  onMouseEnter() {
    const factory = this.cfr.resolveComponentFactory(TooltipComponent);
    const component = this.vcr.createComponent(factory);
    component.instance.content = this.appTooltip;
    component.instance.ref = this.el.nativeElement;
  }

  @HostListener('mouseleave')
  onmouseleave() {
    this.vcr.clear();
  }
}
