import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { App } from '../../models/app.model';
import { AppsService } from 'src/app/shared/services/apps.service';

@Component({
  selector: 'app-rotate-credential',
  templateUrl: 'rotate-credential.component.html',
  styleUrls: ['./rotate-credential.component.scss'],
})
export class RotateCredentialComponent implements OnInit {
  @Input() data: any;
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(
    private analyticsService: AnalyticsService,
    private appService: AppsService
  ) {}

  public app: App;
  public appId: string;
  public environment: string;
  public showReasonStep = true;
  public succesActive = false;
  public loadingActive = false;
  public errorActive = false;
  public rotationReasonObj: any;
  public otherReason = '';
  public targetDate: any;
  public currentImgSrc = '/assets/svg/refresh.svg';
  public currentImgId = 'refresh-logo';
  public rotationReasons = [
    { name: 'REQUEST FROM CAPITAL ONE', value: 'c1Request' },
    { name: 'APP OWNERSHIP CHANGE', value: 'ownershipChange' },
    { name: 'OTHER', value: 'other' },
  ];
  // Error variables
  errorMessage =
    'Open a ticket providing some quick information, and we’ll help you with this rotation.';

  ngOnInit(): void {
    this.app = this.data.app;
    this.appId = this.data.appId;
    this.environment = this.data.env;
  }

  closeModal($event) {
    this.analyticsService.pubSubEvent({
      name: `form_field_interaction:${this.constructor.name}:close-modal`,
    });
    this.close.emit();
  }

  goToApp($event, appId) {
    window.location.href = `/app-details/${appId}`;
    this.closeModal($event);
  }

  rotateCredential() {
    this.loadingActive = true;
    const rotationReason =
      this.rotationReasonObj === 'other'
        ? this.rotationReasonObj + '-' + this.otherReason
        : this.rotationReasonObj;
    this.analyticsService.pubSubEvent({
      name: `form_start:${this.constructor.name}:rotate-${this.environment}-app-credential-${rotationReason}`,
    });
    this.appService
      .rotateCredentials(this.appId, this.environment, rotationReason)
      .subscribe(
        res => {
          this.currentImgSrc = '/assets/svg/success-large.svg';
          this.currentImgId = 'success-logo';
          this.loadingActive = false;
          this.succesActive = true;
          const future = new Date();
          this.targetDate = future.setDate(future.getDate() + 30);
          this.analyticsService.pubSubEvent({
            name: `form_complete:${this.constructor.name}:rotate-${this.environment}-app-credential-success`,
          });
        },
        error => {
          this.currentImgSrc = '/assets/svg/error_icon.svg';
          this.currentImgId = 'error-logo';
          this.loadingActive = false;
          this.errorActive = true;
          this.analyticsService.pubSubEvent({
            name: `application_submit_failure:${this.constructor.name}:rotate-${this.environment}-app-credential-failure`,
          });
        }
      );
  }

  proceedToAgree() {
    this.showReasonStep = !this.showReasonStep;
  }
}
