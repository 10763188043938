import { Component, OnInit, Input } from '@angular/core';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'error-overlay',
  template: `
    <div class="error-overlay">
      <div class="error-icon">
        <img src="/assets/svg/errorIconLarge.svg" alt="Error Icon" />
      </div>
      <div class="error-msg">{{ message }}</div>
    </div>
  `,
  styleUrls: ['./error-overlay.component.scss'],
})
export class ErrorOverlayComponent implements OnInit {
  @Input() message: string;

  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit() {
    this.analyticsService.pubSubEvent({
      name: `application_submit_failure:${this.constructor.name}:${this.message}`,
    });
  }
}
