import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'warning',
  template: `
    <div *ngIf="showWarning" class="warning">
      <img
        *ngIf="showIcon"
        class="warning-icon"
        src="assets/svg/cautionIcon.svg"
        alt="Caution Icon"
        aria-label="Caution Icon"
      />
      <div class="text">{{ message }}</div>
      <img
        role="button"
        tabindex="0"
        id="close-warning"
        alt="close"
        src="/assets/svg/close.svg"
        (click)="closeWarning()"
      />
    </div>
  `,
  styleUrls: ['./warning.component.scss'],
})
export class WarningComponent implements OnInit {
  @Input() message: string;
  @Input() icon: string;
  @Input() cancel: boolean;

  // Show icon by default
  showWarning = true;
  showIcon = true;

  ngOnInit() {
    if (this.icon && this.icon.toUpperCase() === 'FALSE') {
      this.showIcon = false;
    }
  }

  closeWarning() {
    this.showWarning = false;
  }
}
