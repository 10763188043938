import { Update } from '@ngrx/entity';
import { AppMetrics } from '../../core/models/app-metrics.model';
import { ConnectedProduct } from '../../core/models/connected-product.model';
import { StoreStates } from '../../core/models/store-states.enum';

import { DashboardActions, DashboardActionTypes } from './dashboard.actions';
import { appsAdapter, AppState } from './entities/apps.entity';
import {
  overviewMetricsAdapter,
  OverviewMetricsState,
} from './entities/overview-metrics.entity';
import {
  teamMemberAdapter,
  TeamMemberState,
} from './entities/team-member.entity';

export interface DashboardState {
  apps: AppState;
  teamMembers: TeamMemberState;
  overviewMetrics: OverviewMetricsState;
  productsAvailable: ConnectedProduct[];
  registerApp: { isSuccess: boolean; appId: string };
  environmentAvailable: any;
}

export const initialState: DashboardState = {
  apps: appsAdapter.getInitialState({ appsLoaded: StoreStates.UNKNOWN }),
  teamMembers: teamMemberAdapter.getInitialState(),
  overviewMetrics: overviewMetricsAdapter.getInitialState(),
  productsAvailable: [],
  registerApp: { isSuccess: false, appId: '' },
  environmentAvailable: { it: false, sandbox: false, production: false },
};

export function reducer(
  state: DashboardState = initialState,
  action: DashboardActions
): DashboardState {
  switch (action.type) {
    case DashboardActionTypes.StoreAppsAction:
      return {
        ...state,
        apps: appsAdapter.setAll(action.payload.apps, {
          ...state.apps,
          appsLoaded: action.payload.appsLoading,
        }),
      };
    case DashboardActionTypes.LoadAppsAction:
      return {
        ...state,
        apps: {
          ...state.apps,
          appsLoaded: StoreStates.LOADING,
        },
      };
    case DashboardActionTypes.StoreAppIconAction:
      return {
        ...state,
        apps: appsAdapter.updateOne(action.payload.app, state.apps),
      };
    case DashboardActionTypes.LoadAppInfoAction:
      return {
        ...state,
        apps: appsAdapter.updateOne(
          {
            id: action.payload.appId,
            changes: { appDetailsLoaded: StoreStates.LOADING },
          },
          state.apps
        ),
      };
    case DashboardActionTypes.StoreAppInfoAction:
      return {
        ...state,
        apps: appsAdapter.upsertOne(action.payload.app, state.apps),
        overviewMetrics: overviewMetricsAdapter.addMany(
          action.payload.overviewMetrics,
          state.overviewMetrics
        ),
      };
    case DashboardActionTypes.LoadAppOverviewMetricsAction:
      const updatedMetrics: Update<AppMetrics> = {
        id: action.payload.appClientId,
        changes: {
          metricsState: StoreStates.LOADING,
        },
      };
      return {
        ...state,
        overviewMetrics: overviewMetricsAdapter.updateOne(
          updatedMetrics,
          state.overviewMetrics
        ),
      };
    case DashboardActionTypes.StoreAppOverviewMetricsAction:
    case DashboardActionTypes.StoreAppOverviewMetricsErrorAction:
      return {
        ...state,
        overviewMetrics: overviewMetricsAdapter.updateOne(
          action.payload.overviewMetrics,
          state.overviewMetrics
        ),
      };
    case DashboardActionTypes.UpdateEnvironmentsAvailableAction:
      return {
        ...state,
        environmentAvailable: {
          ...state.environmentAvailable,
          it: action.payload.itAvailable,
          production: action.payload.productionAvailable,
        },
      };
    case DashboardActionTypes.StoreRegisterAppAction:
      return {
        ...state,
        registerApp: {
          isSuccess: action.payload.isSuccess,
          appId: action.payload.appId,
        },
      };
    default:
      return state;
  }
}

export const selectAppsState = (state: DashboardState) => state.apps;

export const { selectAll: selectAllApps } = appsAdapter.getSelectors(
  selectAppsState
);
