import { AxiosHeaders, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Injectable, inject } from '@angular/core';
import { of, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { AuthEngineEndpoints } from '../../shared/services/service-endpoints';
import { CaliperXService } from '../../shared/services/caliperx.service';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  private caliperXService = inject(CaliperXService);
  private headers: AxiosHeaders = new AxiosHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json;v=1')
    .set('responseType', 'json');
  private analyticsService = inject(AnalyticsService);

  /** Attempt to upload new user portrait */
  public saveUserPortrait(image: string): any {
    /**
     * For some reason, the request isn't being intercepted.  We have to add the token now.
     */
    const requestConfig: AxiosRequestConfig = {
      headers: this.headers,
      url: AuthEngineEndpoints.USER_PROFILE_PICTURE,
      method: 'POST',
      data: { image: image },
    };
    return from(this.caliperXService.gatewayRequest(requestConfig)).pipe(
      map((resp: AxiosResponse) => resp.data),
      catchError(error => this.handleError(error, 'saveUserPortrait'))
    );
  }

  /** Return base64 encoded user portrait, null if failure/not available */
  public getUserPortrait(): any {
    const requestConfig: AxiosRequestConfig = {
      headers: this.headers,
      url: AuthEngineEndpoints.USER_PROFILE_PICTURE,
      method: 'GET',
    };
    return from(this.caliperXService.gatewayRequest(requestConfig)).pipe(
      map((resp: AxiosResponse) => resp.data.image),
      catchError(error => this.handleError(error, 'getUserPortrait'))
    );
  }

  private handleError(error: any, methodName?: string) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.analyticsService.pubSubEvent({
      name: `application_submit_failure:${this.constructor.name}:error-${methodName}-user-portrait:${errorMessage}`,
    });
    return of(null);
  }
}
