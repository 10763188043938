import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { FeatureToggleEndpoints as endpoints } from './service-endpoints';
import { timeouts } from '../../../environments/environment';
import { CaliperX2Service } from './caliperx2.service';
import { AxiosRequestConfig } from 'axios';

@Injectable()
export class FeatureToggleService {
  constructor(private caliperX2Service: CaliperX2Service) {}

  /**
   * Health Check for a given feature toggle, returns false if the feature toggle is disabled, true if enabled
   * @param featureName
   * @returns boolean
   * EDH-212242
   */
  getFeatureToggle(featureName: string): Observable<boolean> {
    const url = `${endpoints.GET_FEATURE_TOGGLE}${featureName}`;
    const requestConfig: AxiosRequestConfig = {
      url,
      method: 'GET',
    };
    return from(this.caliperX2Service.gatewayRequest(requestConfig)).pipe(
      timeout(timeouts.http),
      map(toggle => toggle.data as boolean)
    );
  }
}
