import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { UserSettingsService } from './user-settings.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import {
  PortraitLoad,
  PortraitStore,
  PortraitUpload,
  UserSettingsActionTypes,
} from './user-settings.actions';
import { of } from 'rxjs';

@Injectable()
export class UserSettingsEffects {
  portraitLoad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PortraitLoad>(UserSettingsActionTypes.PortraitLoadAction),
        switchMap(action => this.userSettingsService.getUserPortrait()),
        catchError(err => {
          console.error(`Failed retrieving User Portrait: ${err}`);
          this.analyticsService.pubSubEvent({
            name: `application_submit_failure:UserSettingsEffects:failed-retrieving-user-portrait:${err}`,
          });
          return of(null);
        }),
        map(portrait => new PortraitStore({ portrait }))
      ),
    { dispatch: true }
  );

  portraitUpload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserSettingsActionTypes.PortraitUploadAction),
        switchMap((action: any) => {
          return this.userSettingsService.saveUserPortrait(
            action.payload.portrait
          );
        }),
        map(resp => {
          window.caliperx.accountInfo.image = resp['image'];
          return resp['image']
            ? new PortraitStore({ portrait: resp['image'] })
            : null;
        }),
        catchError(err => {
          console.error(`Failed uploading new User Portrait: ${err}`);
          this.analyticsService.pubSubEvent({
            name: `application_submit_failure:UserSettingsEffects:failed-uploading-new-user-portrait:${err}`,
          });
          return of(null);
        })
      ),
    { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private userSettingsService: UserSettingsService,
    private analyticsService: AnalyticsService
  ) {}
}
