<section id="get-access" #getAccessElement>
  <img
    id="close-get-access-modal"
    alt="close"
    src="/assets/svg/close.svg"
    (click)="closeModal($event)"
  />
  <h1 *ngIf="!successActive && !errorActive && !loadingActive">
    Get Access
  </h1>
  <section *ngIf="requireAuthActive" id="unauthenticated">
    <div class="text">
      In order to get access to our products, you'll need to sign in. Don't have
      an account?
      <a id="sign-up-link" routerLink="sign-up" (click)="closeModal($event)"
        >Sign Up</a
      >.
    </div>
    <div class="btn">
      <button
        id="sign-in-button"
        [routerLink]="['/sign-in']"
        [queryParams]="{
          return_to: '/products/' + convertToUrl(product.name),
          getAccess: 'true'
        }"
        routerLinkActive="active"
        (click)="closeModal($event)"
      >
        Sign In
      </button>
    </div>
  </section>
  <section *ngIf="selectAppActive" id="select-app">
    <div class="text">
      Select an existing app to connect, or create a new one.
    </div>
    <div class="form-content">
      <label id="app-select-label">APP</label>
      <div class="form-content-row">
        <div class="select-bar">
          <select
            class="form-control devex-carrot"
            [(ngModel)]="app"
            name="product"
            aria-label="Select App Dropdown"
          >
            <ng-container
              *ngIf="apps$ | async | sort: 'ALPHABETICAL':'appName' as apps"
            >
              <option *ngFor="let app of apps" [ngValue]="app">{{
                app.appName
              }}</option>
            </ng-container>
          </select>
        </div>
        <button id="appConnectButton" (click)="connectApp()">
          Connect
        </button>
      </div>
    </div>
    <div class="line"></div>
    <div class="new-app-btn">
      <button
        id="newAppButton"
        class="outline"
        [routerLink]="['/dashboard/register']"
        (click)="closeModal($event)"
      >
        + New App
      </button>
    </div>
  </section>
  <section *ngIf="successActive" id="success">
    <div class="success-icon">
      <img src="assets/svg/iconSuccessLg.svg" />
    </div>
    <h1>Success!</h1>
    <div class="text">Your app is now connected to this product.</div>
    <div class="visit-app-btn">
      <button id="visitAppButton" (click)="closeModal($event, app.appId)">
        Visit App
      </button>
    </div>
  </section>
  <section id="loading-section">
    <div class="spinner-container" *ngIf="loadingActive">
      <spinner id="loading" size="small"></spinner>
    </div>
  </section>
  <section *ngIf="errorActive" id="error">
    <div class="error-icon">
      <img src="assets/svg/errorIconLarge.svg" />
    </div>
    <div class="text">{{ errorMessage }}</div>
  </section>
</section>
