// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  host: 'https://developerit.capitalone.com',
  redirectMatch: /https:\/\/(devexchange-cf\.clouddqtext\.capitalone\.com|developerit\.capitalone\.com|localhost|developer\.capitalone\.com)/,
  authorization_client_id: 'ae2f51500f144406b37d18f786f37f2b',
  medallia: {
    forms: {
      userFeedbackSurvey: 13884 /** 13884 is a cemp form id and only works for testing */,
    },
  },
};

export const timeouts = {
  /** Default timeout for http requests */
  http: 30000,
  /** Timeout for deleting an applicaiton */
  appDeletion: 60000,
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
