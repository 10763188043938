import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  selectAuthenticationStatus,
  selectEmail,
} from 'src/app/store/authentication/authentication.selectors';
import { select, Store } from '@ngrx/store';
import { map, take, takeUntil } from 'rxjs/operators';
import { FeatureToggleService } from 'src/app/shared/services/feature-toggle.service';
import { BrowserStorage } from '../models/browser-storage.enum';
import { environment } from 'src/environments/environment';

@Injectable()
export class FeedbackService {
  constructor(
    private store: Store<any>,
    private featureToggleService: FeatureToggleService
  ) {}

  public isAuthenticated$: Observable<boolean>;
  private _destroy: Subject<void> = new Subject();

  activateFeedbackTool() {
    // called from app component to activate feedback tool , initially set to hidden
    // check to feature toggle , feature key -"feedback"
    this.cempDigitalIsEnabled();
  }

  private getCurrentPageURL(wdw) {
    return wdw.document.location.href;
  }

  private cempDigitalIsEnabled() {
    this.featureToggleService
      .getFeatureToggle('feedback')
      .pipe(take(1))
      .subscribe(resp => {
        // if feature toggle is enabled then inject the feedback tag
        if (resp) {
          const wdw = window as any;
          // CEMP feeback tool needs to be embedded, initially set to hidden

          this.initializeCempDataLayer(wdw, false);
          this.initializeCempDigital(wdw);
        }
      });
  }

  private initializeCempDataLayer(wdw: any, shouldShowFeedback: boolean) {
    wdw.cempDataLayer = wdw.cempDataLayer || {};
    wdw.cempDataLayer.showButton = shouldShowFeedback;
    wdw.cempDataLayer.country = 'USA'; // Add more CEMP Data Layer assignment as appropriate
    wdw.cempDataLayer.responseURL = this.getCurrentPageURL(wdw);
  }

  private initializeCempDigital(wdw: any) {
    // if the feedback tool already exists then update the view to hide/show it.
    /** Subscribe to the authentication flag in store */
    this.isAuthenticated$ = this.store.pipe(
      select(selectAuthenticationStatus),
      takeUntil(this._destroy)
    );
    this.isAuthenticated$.subscribe(auth => {
      wdw.cempDataLayer = wdw.cempDataLayer || {};
      if (auth) {
        // authenticated so updating the page view
        wdw.cempDataLayer.showButton = true;
        // is authenticated and called from app component so updating view to show feedback tool"
        if (
          wdw.cempDigital != null &&
          typeof wdw.cempDigital.updatePageView === 'function'
        ) {
          if (wdw.cempDataLayer.showButton !== undefined) {
            wdw.cempDataLayer.showButton = true;
          }
          wdw.cempDigital.updatePageView();
        } else {
          this.activateCEMPDigital(wdw);
        }
      } else {
        // called for the first time to initialize the view so calling to activate view to hide feedback tool"
        this.activateCEMPDigital(wdw);
      }
    });
  }

  activateCEMPDigital(wdw) {
    // called for the first time to initialize the view so calling to activate view to hide feedback tool"
    function startCempDigital() {
      if (
        wdw.activateCempDigital != null &&
        typeof wdw.activateCempDigital === 'function'
      ) {
        wdw.activateCempDigital();
        return true;
      }
      return false;
    }

    if (startCempDigital() === false) {
      if (wdw.addEventListener) {
        window.addEventListener('cempDigitalInjected', startCempDigital, false);
      } else if (wdw.attachEvent) {
        wdw.attachEvent('on' + 'cempDigitalInjected', startCempDigital);
      }
    }
  }

  // called on page/url change - show the feedback tool for all pages when authenticated and only for documentation page when unauthenticated
  setFeedbackTool(isDocumentationPage?: boolean) {
    /** Subscribe to the authentication flag in store */
    this.isAuthenticated$ = this.store.pipe(
      select(selectAuthenticationStatus),
      takeUntil(this._destroy)
    );
    this.isAuthenticated$.subscribe(auth => {
      const wdw = window as any;
      wdw.cempDataLayer = wdw.cempDataLayer || {};
      let shouldShouldFeedback = false;
      if (wdw.cempDataLayer.responseURL !== undefined) {
        wdw.cempDataLayer.responseURL = this.getCurrentPageURL(wdw);
      }
      if (auth || isDocumentationPage) {
        shouldShouldFeedback = true;
      }
      if (wdw.cempDataLayer.showButton !== undefined) {
        wdw.cempDataLayer.showButton = shouldShouldFeedback;
      }
      if (
        wdw.cempDigital != null &&
        typeof wdw.cempDigital.updatePageView === 'function'
      ) {
        wdw.cempDigital.updatePageView();
      }
    });
  }

  /**
   * Opens the User Feedback Modal if showUserFeedback property in session storage is true
   */
  launchFeedbackModal(): Observable<boolean> {
    const showFeedbackModal = JSON.parse(
      sessionStorage.getItem(BrowserStorage.SHOW_USER_FEEDBACK)
    );
    const isProdEnv = environment.production;
    return this.store.pipe(
      select(selectEmail),
      map(email => {
        const isValidFeedbackUser = email?.includes('@capitalone.com');
        return showFeedbackModal && !(isProdEnv && isValidFeedbackUser);
      }),
      takeUntil(this._destroy)
    );
  }

  launchSurvey(formId: number) {
    const wdw = window as any;
    if (
      wdw.cempDigital != null &&
      formId &&
      typeof wdw.cempDigital.loadForm === 'function' &&
      typeof wdw.cempDigital.showForm === 'function'
    ) {
      this.loadSurveyForm(wdw, formId);
    }
  }

  private loadSurveyForm(wdw: any, formId: number) {
    if (wdw.cempDigital.loadForm(formId)) {
      wdw.cempDigital.showForm(formId);
    }
  }
}
