import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { RouterHistoryService } from '../../../../shared/services/router-history.service';
import {
  selectAuthenticationStatus,
  selectEmail,
} from '../../../../store/authentication/authentication.selectors';
import { Subject, Observable } from 'rxjs';
import { AppState } from '../../../../core/reducers';
import { select, Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { StateService } from '../../../../core/services/state.service';
import { clearSession } from '../../../../store/authentication/authentication.actions';
import { LogoutAction } from '../../../../core/models/logout-action.enum';
import { StateObject } from '../../../../core/models/state-object.model';
import { AuthenticationType } from '../../../../core/models/authentication-type.enum';
const styling = 'font-weight: 800; font-size: 12px; color: blue';

@Component({
  selector: 'sign-in-callback',
  template: `
    <section id="landing-page">
      <div id="landing-page-subtitle">
        We are redirecting you to your authenticated experience.
      </div>
      <p>
        If you aren't redirected, please click the button below.
      </p>
      <button class="outline" (click)="goHome()">Go To My Dashboard</button>
    </section>
  `,
  styleUrls: ['./sign-in-result.component.scss'],
})
export class SignInResultComponent implements OnInit {
  caliperx = (window as any).caliperx;
  public previousUrl: string;
  public currentUrl: string;
  previousUrlViaRouterHistoryService$ = this.routerHistoryService.previousUrl$;
  public isAuthenticated$: Observable<boolean>;
  public loginAttemptInProgress = false;
  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
    private routerHistoryService: RouterHistoryService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private stateService: StateService
  ) {}
  stateValidationError: boolean;
  timerId: any;
  state: any;
  private _destroy: Subject<void> = new Subject();

  ngOnInit() {
    this.state = this.route.snapshot.queryParams['state'];

    // If error returned in url, invalidate tokens and log
    if (this.route.snapshot.queryParams['error']) {
      this.analyticsService.pubSubEvent({
        name: `application_submit_failure:${this.constructor.name}:error-invalid_state_returned`,
      });
      /** Send call to invalidate the tokens */
      this.store.dispatch(
        clearSession({ logoutAction: LogoutAction.LOGOUT_ERROR })
      );
    } else {
      // Error not returned in the url - successful login if state validation is successful
      if (this.state) {
        const recievedState = this.state;
        const isValidState = this.stateService.validateState(
          decodeURIComponent(recievedState)
        );
        if (!isValidState) {
          // Invalid state
          console.error(
            'Recieved oauth state does not match stored oauth state'
          );
          this.analyticsService.pubSubEvent({
            name: `application_submit_failure:${this.constructor.name}:error-attempt-login-state_validation_mismatch`,
          });
          this.stateValidationError = true;
        } else {
          this.stateValidationError = false;
        }
      } else if (
        /** If the returned state doesn't exist or match, error and logout - handled as part of refresh session code */
        !this.state ||
        !this.stateService.validateState(this.state)
      ) {
        this.analyticsService.pubSubEvent({
          name: `application_submit_failure:${this.constructor.name}:error-silent-refresh-oauth_state_validation_mismatch`,
        });
        // In case the state is empty or invalid , invalidate the tokens and logout , show the error page
        if (!this.state) {
          console.error('OAuth state was not passed, invalidating session.');
        }
        if (!this.stateService.validateState(this.state)) {
          console.error('Invalid OAuth state, invalidating session.');
        }
        return this.store.dispatch(
          clearSession({ logoutAction: LogoutAction.LOGOUT_ERROR })
        );
      }
      this.loginAttemptInProgress = false;

      // Need to grab email, but the component will be destroyed before we can, so we will unsubscribe once task is complete
      const grabEmailProvider = this.store
        .pipe(select(selectEmail))
        .subscribe(email => {
          if (email != null && email.length > 0) {
            this.analyticsService.pubSubEvent({
              name: `login_complete:${
                this.constructor.name
              }:sign-in-success-${this.getEmailProvider(email)}`,
            });
            grabEmailProvider.unsubscribe();
          }
        });
      /** Subscribe to the authentication flag in store */
      this.isAuthenticated$ = this.store.pipe(
        select(selectAuthenticationStatus),
        filter(authenticated => authenticated !== undefined),
        takeUntil(this._destroy)
      );
      this.isAuthenticated$.subscribe(auth => {
        /** If login was successful */
        console.log('%cauth:', styling, auth);
        if (auth) {
          /** Get the return to path (always populated) */
          let state = '',
            nonce = '',
            decryptedState = {
              returnToPath: '',
              authenticationAction: '' as AuthenticationType,
            } as StateObject,
            path = '';
          try {
            state = this.stateService.getState();
            nonce = this.stateService.retrieveStateNonce();
            decryptedState = this.stateService.decryptState(state, nonce);
            path = decryptedState.returnToPath;
          } catch (e) {
            console.error('there was an error with state decrypting state', e);
          }

          console.group(
            '%cSIGN-IN-RESULT',
            styling,
            { state, nonce },
            decryptedState
          );

          /** Clear state information */
          this.stateService.clearState();
          /** If valid state */
          if (!this.stateValidationError) {
            // No state tampering, allow for return after authentication (stored value or dashboard)

            // Check for invalid returnTo path, set to dashboard if not valid
            if (!this.stateService.validateReturnToPath(path)) {
              path = '/dashboard';
            }

            // Navigate to return to or dashboard
            window.location.href = path;
          } else if (this.stateValidationError) {
            /** Track the fact that an invalid state was returned */
            this.analyticsService.pubSubEvent({
              name: `application_submit_failure:${this.constructor.name}:invalid_oauth_state_returned`,
            });
            /** Send call to invalidate the tokens */
            this.store.dispatch(
              clearSession({ logoutAction: LogoutAction.LOGOUT_ERROR })
            );
          } else {
            /**  No returnTo - go to dashboard */
            this.goHome();
          }
        }
      });
    }
  }

  public getEmailProvider(email: string) {
    let provider = '';
    if (email) provider = email.split('@')[1];
    return provider || '';
  }

  public goHome() {
    this.router.navigate(['dashboard']);
  }
}
