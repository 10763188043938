import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { CoreModule } from './app/core/core.module';

const getFeatureToggle = async () => {
  const response = await fetch(
    '/protected/23988/third-party-engine/featuretoggle/health?feature=caliperx2-dxo-ui',
    {
      headers: {
        Accept: 'application/json;v=1',
      },
    }
  );
  const data = response.json();
  return data;
};

const loadScript = async () => {
  const featureToggleData = await getFeatureToggle();
  const isCaliperX2 = Boolean(featureToggleData);

  const script = document.createElement('script');
  if (isCaliperX2) {
    script.src = '/caliperx2/caliperx2.js';
    script.setAttribute('data-components', 'true');
    script.setAttribute('data-core-wc', 'true');
    script.setAttribute('data-dxds-styles', 'true');
    script.setAttribute('data-new-relic', 'false');
    script.setAttribute('data-cemp', 'false');
    script.setAttribute('data-gateway', 'true');
    script.setAttribute(
      'data-potomac',
      '{"appId":"api_digital_ext","env":"qa","oneTagProfile":"others"}'
    );
  } else {
    script.src = '/caliperx/assets/latest/caliperx.bootstrap.js';
    script.setAttribute('data-primarystyles', 'false');
    script.setAttribute('data-ui-wc', 'false');
    script.setAttribute('data-core-wc', 'false');
    script.setAttribute('data-form-wc', 'false');
  }

  return new Promise<void>(resolve => {
    script.onload = () => {
      const checkCaliperX = () => {
        if (window.caliperx2) {
          const checkGateway = () => window['caliperx2']?.gateway;
          const isAuthenticated = window.caliperx2.authentication?.state?.getIsAuthenticated();
          if (typeof isAuthenticated === 'boolean' && checkGateway()) {
            resolve(); // Resolve when getIsAuthenticated returns a boolean
          } else {
            setTimeout(checkCaliperX, 50); // Retry until getIsAuthenticated returns a boolean
          }
        } else if (window.caliperx) {
          if (typeof window.caliperx.accountInfo?.authenticated === 'boolean') {
            resolve();
          } else {
            setTimeout(checkCaliperX, 50); // Retry until authenticated is a boolean
          }
        } else {
          setTimeout(checkCaliperX, 50); // Retry until caliperx or caliperx2 is available
        }
      };
      checkCaliperX();
    };
    document.body.appendChild(script);
  });
};

const bootstrapApp = async () => {
  await loadScript();

  platformBrowserDynamic()
    .bootstrapModule(CoreModule)
    .catch(err => console.error(err));
};

bootstrapApp();
