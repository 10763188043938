import { Component, Input } from '@angular/core';
import { SlateLink } from './slate-link.model';

@Component({
  selector: 'slate',
  template: `
    <section class="slate" [attr.index]="0">
      <div class="light">
        <a
          *ngFor="let link of links"
          class="link-container"
          [routerLink]="link.url"
        >
          <h3>{{ link.title }}</h3>
          <h5>{{ link.subtitle }}</h5>
        </a>
      </div>
      <div class="dark" *ngIf="darkLink">
        <a class="link-container" tabindex="0" [routerLink]="darkLink.url">
          <h3>{{ darkLink.title }}</h3>
        </a>
      </div>
    </section>
  `,
  styleUrls: ['slate.component.scss'],
})
export class SlateComponent {
  @Input() links: SlateLink[];
  @Input() darkLink: SlateLink;
}
