import { Injectable } from '@angular/core';

@Injectable()
export class AnalyticsService {
  caliperx = (window as any).caliperx;

  public pubSubPageView() {
    setTimeout(() => {
      this.caliperx.services.potomac.pageView();
    }, 500);
  }

  public pubSubEvent(eventData: any) {
    this.caliperx.services.potomac.interaction('default', 'message', eventData);
  }

  // needs to move to a different service as this doesn't belong in the analytics service !!!!
  //return product name along with version when major versions exists
  getProductName(product) {
    return product.isMajorVersionAvailable
      ? product.apiName + ' (V' + product.apiId.split('-')[1] + ')'
      : product.apiName;
  }
  //check if major version exists for a product, if exists display the version along with product name
  // needs to move to a different service as this doesn't belong in the analytics service !!!!
  checkIfConnectedProdExists(envConnectedProducts) {
    let apiVersionMap = new Map();
    envConnectedProducts.forEach(product => {
      let apiId = product.apiId.split('-')[0];
      if (apiVersionMap.get(apiId) !== undefined)
        apiVersionMap.set(apiId, true);
      else apiVersionMap.set(apiId, false);
    });
    envConnectedProducts.map(prod => {
      let prodId = prod.apiId.split('-')[0];
      prod.isMajorVersionAvailable = apiVersionMap.get(prodId);
      return prod;
    });
    return envConnectedProducts;
  }
}
