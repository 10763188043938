import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';

@Directive({ selector: '[track-click]' })
export class TrackClickDirective {
  @Input() eventName: string;

  constructor(private analyticsService: AnalyticsService) {}

  @HostListener('click', ['$event.target']) onClick(target) {
    this.trackClick();
  }
  // IMPORTANT:
  // To properly use this directive, one must add 'track-click' to an anchor link element in order to track the click.
  // The 'eventName' property is also added with the corresponding metric value. For example:
  // <a
  //   track-click
  //   eventName="global-footer-products-link"
  //   routerLink="products"
  //   >Browse All</a
  // >
  // The above sends a 'navigation_click' metric to our dashboards in the following format:
  // {pagePath}:navigation_click:global-footer-products-link
  //
  // This directive can be added to any given link through dxo-ui that is of interest to track.
  trackClick() {
    try {
      if (this.eventName) {
        this.analyticsService.pubSubEvent({
          name: `navigation_click:${this.eventName}`,
        });
      }
    } catch (e) {
      console.error('error:', e);
    }
  }
}
