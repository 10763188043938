import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { BrowserStorage } from '../models/browser-storage.enum';
import { tap } from 'rxjs/operators';
import {
  AuthEngineEndpoints,
  AuthenticationEndpoints as endpoints,
} from '../../shared/services/service-endpoints';

/** Add headers related to message tracking. E.G. x-correlation-id & x-message-id */
@Injectable()
export class MessageTrackingInterceptor implements HttpInterceptor {
  correlationId: string;

  constructor() {
    // Set correlation id
    let correlationId = sessionStorage.getItem(BrowserStorage.CORRELATION_ID);
    if (!correlationId) {
      // Generate when not already provided
      correlationId = uuidv4();
      sessionStorage.setItem(BrowserStorage.CORRELATION_ID, correlationId);
    }
    this.correlationId = correlationId;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Attempt to get hostname from request url
    let hostname = null;
    try {
      hostname = new URL(req.url).hostname;
    } catch {}
    try {
      // Check if request url will target our backend
      if (req.url.startsWith('/') || window.location.hostname === hostname) {
        const messageId = uuidv4();
        req = req.clone({
          setHeaders: {
            'correlation-id': this.correlationId,
            'x-correlation-id': this.correlationId,
            'x-message-id': messageId,
          },
        });
      }
    } catch (error) {
      console.error(`Failure adding tracking headers: ${error}`);
    }
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // handling response for the initial /token call which returns a 200 response
            // initiate a login-callback.
            // This is added to handle the final login call "auth-callback", returning a 302 to sign-in/result page
            // hence the login flow was broken down such that initial call /token returns a 200 response
            // with the location header set to login-callback , which is then called using a window.location.href
            // instead of httpclient call
            if (
              event.url.indexOf(AuthEngineEndpoints.AUTHENTICATION_URL) !==
                -1 ||
              event.url.indexOf(AuthEngineEndpoints.USER_OTP_VALIDATE) !== -1
            ) {
              // if state is set in location header from otp validate response,
              // then follow the https response logic and do not do a windows.location.href to login-callback
              let params = '';
              if (event.body?.authCorrelationId) {
                params = 'authCorrelationId=' + event.body?.authCorrelationId;
              }

              if (event.body?.login_error) {
                params = params + '&login_error=true';
              }
              // TODO please do not overwrite constants.
              if (params !== '') {
                endpoints.LOGIN_CALLBACK =
                  endpoints.LOGIN_CALLBACK + '?' + params;
              }
              window.location.href = endpoints.LOGIN_CALLBACK;
            }
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
          }
        }
      )
    );
  }
}
