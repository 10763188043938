import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PasswordGuidelinesTileComponent } from './password-guidelines-tile.component';

@NgModule({
  imports: [CommonModule],
  exports: [PasswordGuidelinesTileComponent],
  declarations: [PasswordGuidelinesTileComponent],
})
export class PasswordGuidelinesTileModule {}
