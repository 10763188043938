<section id="user-feedback-section">
  <div class="user-feedback-header">
    <div class="close-button-section">
      <button
        class="icon"
        id="close-user-feedback-button"
        tabindex="0"
        (click)="closeModal()"
        (keydown.enter)="closeModal()"
      >
        <img
          class="user-feedback-close-icon"
          src="/assets/svg/close.svg"
          alt="close-feedback-modal"
        />
      </button>
    </div>
  </div>
  <div class="user-feedback-content">
    <div class="user-feedback-title">
      <div class="user-feedback-title-label">
        How’s your experience so far?
      </div>
    </div>
    <div class="user-feedback-body">
      <div class="user-feedback-body-text">
        Your input helps us improve.
      </div>
    </div>
  </div>
  <div class="user-feedback-footer">
    <div class="user-feedback-action">
      <button
        class="gravity-blue"
        id="provide-feedback-button"
        tabindex="0"
        (keydown.enter)="openFeedback()"
        name="provide-feedback-button"
        (click)="openFeedback()"
      >
        Provide Feedback
      </button>
    </div>
  </div>
</section>
