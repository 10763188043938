import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { AppState } from './reducers';
import {
  checkAuthenticationState,
  clearSession,
  getUserDetails,
  setAuthenticatedState,
  setUnauthenticatedState,
} from '../store/authentication/authentication.actions';
import { FeedbackService } from './services/feedback.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { GlobalAlertsComponent } from './components/global-alerts/global-alerts.component';
import { BrowserStorage } from './models/browser-storage.enum';
import { LogoutAction } from './models/logout-action.enum';
import { selectAuthenticationStatus } from '../store/authentication/authentication.selectors';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  template: `
    <section id="app" [ngClass]="{ 'hide-overflow': mobileMenuOpen$ | async }">
      <div id="sticky-top-nav">
        <global-alerts
          #alerts
          (alertVisible)="updateHeaderSpacing($event)"
        ></global-alerts>
      </div>
      <section
        id="mainContent"
        tabindex="-1"
        #main
        [ngClass]="{ 'banner-margin': addBannerMargin }"
      >
        <router-outlet></router-outlet>
      </section>
      <modal-container></modal-container>
    </section>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private analyticsService: AnalyticsService,
    private feedbackService: FeedbackService
  ) {}
  caliperx = (window as any).caliperx;

  mobileMenuOpen$: Observable<boolean>;
  addBannerMargin = false; // Updated when alert banner is visible to show main content

  @ViewChild('main') mainContent: ElementRef;
  @ViewChild('alerts') globalAlerts: GlobalAlertsComponent;

  private _destroy: Subject<void> = new Subject();

  ngOnInit() {
    this.feedbackService.activateFeedbackTool();
    this.store.dispatch(checkAuthenticationState());

    sessionStorage.setItem(BrowserStorage.SHOW_USER_FEEDBACK, 'true');

    // Set focus to content at end of all routing events
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.mainContent.nativeElement.focus();
        // Global page view tracking
        this.analyticsService.pubSubPageView();
      });

    window.addEventListener('storage', event => {
      if (event.storageArea === localStorage) {
        const relogin = localStorage.getItem('isReloginReq');
        if (relogin === 'Yes') {
          // Perform logout
          this.store.dispatch(setUnauthenticatedState());
          // Navigate to relogin page
          this.router.navigate(['/relogin']);
        }
      }
    });

    // Ensure we're logged out completely / state * store are cleaned up when caliper x logs out
    document.addEventListener('caliperx:unauthenticated', () => {
      this.store.dispatch(
        clearSession({ logoutAction: LogoutAction.LOGOUT_CALIPERX })
      );
    });

    // Ensure we know that caliper has finished checking authentication
    document.addEventListener('caliperx:authenticationChecked', () => {
      if (this.caliperx.accountInfo.authenticated) {
        this.store.dispatch(setAuthenticatedState());
      }
    });

    // Ensure we know that caliper has finished checking authentication
    document.addEventListener('caliperx:loaded', () => {
      if (this.caliperx.accountInfo.authenticated) {
        this.store.dispatch(setAuthenticatedState());
      }
    });

    // If user is authenticated, grab their details
    this.store
      .pipe(
        select(selectAuthenticationStatus),
        takeUntil(this._destroy),
        distinctUntilChanged()
      )
      .subscribe(auth => {
        if (auth) {
          this.store.dispatch(getUserDetails());
        }
      });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  updateHeaderSpacing(bannerVisible) {
    // Update bool when banner visible to add style class
    this.addBannerMargin = bannerVisible;
  }
}
