<section id="rotate-credentials" #rotateCredentials>
  <img
    id="close-credential-rotation-modal"
    alt="close"
    src="/assets/svg/close.svg"
    (click)="closeModal($event)"
  />
  <div *ngIf="!loadingActive" class="image-div">
    <img id="{{ currentImgId }}" src="{{ currentImgSrc }}" />
  </div>
  <h1 *ngIf="showReasonStep && !succesActive && !loadingActive && !errorActive">
    Start Rotation
  </h1>
  <h1
    *ngIf="!showReasonStep && !succesActive && !loadingActive && !errorActive"
  >
    Confirm Rotation
  </h1>
  <h1 *ngIf="succesActive && !loadingActive && !errorActive">
    New Client Secret Generated
  </h1>
  <h1 id="error-title" *ngIf="!succesActive && !loadingActive && errorActive">
    Looks like we couldn't create a new client secret
  </h1>
  <form
    *ngIf="!succesActive && !loadingActive && !errorActive"
    class="product"
    (submit)="rotateCredential()"
  >
    <section class="form-section">
      <div *ngIf="showReasonStep" class="disclosure-content">
        Get started by sharing why you need a new client secret (and after that,
        you’ll update the associated apps). Make sure you’ll be able to do these
        updates before continuing here. Keep in mind—you won’t need a change
        order to do this.
      </div>
      <div *ngIf="showReasonStep" class="form-group">
        <label>Choose why you're rotating the client secret.</label>
        <select
          id="reason-selector"
          name="rotationReason"
          [(ngModel)]="rotationReasonObj"
        >
          <option disabled selected value="">Select a Reason</option>
          <option
            *ngFor="let reason of rotationReasons"
            [attr.name]="reason.name"
            [ngValue]="reason.value"
            >{{ reason.name }}</option
          >
        </select>
      </div>
      <div
        *ngIf="showReasonStep && rotationReasonObj === 'other'"
        class="form-group"
      >
        <input
          class="otherReason"
          type="text"
          name="otherReason"
          id="otherReason"
          maxlength="50"
          placeholder="Good reason here"
          [(ngModel)]="otherReason"
        />
      </div>
      <div *ngIf="!showReasonStep" class="disclosure-content">
        To continue, confirm that you’ll update your apps to depend on the new
        client secret. You’ll have 30 days before the previous client secret
        expires.
      </div>

      <div *ngIf="showReasonStep" class="continueCancel-div">
        <button id="cancel-btn" type="button" (click)="closeModal($event)">
          Cancel
        </button>
        <button id="continue-btn" type="button" (click)="proceedToAgree()">
          Continue
        </button>
      </div>
      <div *ngIf="!showReasonStep" class="agree-div">
        <button id="cancel-btn" type="button" (click)="closeModal($event)">
          Cancel
        </button>
        <button id="continue-btn" type="submit" (click)="rotateCredential()">
          Confirm
        </button>
      </div>
    </section>
  </form>
  <section
    *ngIf="succesActive && !errorActive && !loadingActive"
    id="success-section"
  >
    <div class="disclosure-content">
      Your new client secret is active and will be ready for use after 10
      minutes. Make sure to update all apps within 30 days (by
      {{ targetDate | date: 'mediumDate' }}).
    </div>
    <div class="success-button-div">
      <button
        id="apps-btn"
        [queryParams]="{
          rotated: 'true'
        }"
        routerLinkActive="active"
        (click)="goToApp($event, appId)"
      >
        Get New Secret
      </button>
    </div>
  </section>
  <section
    *ngIf="loadingActive && !errorActive && !succesActive"
    id="loading-section"
  >
    <div class="spinner-container" *ngIf="loadingActive">
      <spinner id="loading" size="small"></spinner>
    </div>
  </section>
  <section *ngIf="errorActive && !succesActive && !loadingActive" id="error">
    <div class="text">{{ errorMessage }}</div>
    <div class="error-btn-div">
      <button id="close-btn" type="button" (click)="closeModal($event)">
        Close
      </button>
      <button
        id="apps-btn"
        routerLink="/help/tickets"
        (click)="closeModal($event)"
      >
        Open a Ticket
      </button>
    </div>
  </section>
</section>
