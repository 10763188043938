import { Component, Input } from '@angular/core';

@Component({
  selector: 'password-guidelines-tile',
  template: `
    <div id="password-requirements-tile" *ngIf="showGuidelines">
      <div class="password-requirements-tile-title">
        In order to protect your account, your password must:
      </div>
      <ol>
        <li>
          <span>Contain at least 3 of the following:</span>
          <ul>
            <li>Include at least one uppercase letter (A-Z)</li>
            <li>Include at least one lowercase letter (a-z)</li>
            <li>Include at least one number (0-9)</li>
            <li>
              Include at least one special character E.g. (-_./()&#64;$*&!#)
            </li>
          </ul>
        </li>
        <li>
          <span>Be at least 8 characters (max. 64)</span>
        </li>
        <li>
          <span>Not contain your login ID or parts of your name.</span>
        </li>
      </ol>
      <div class="password-requirements-tile-note">
        Please note: your password cannot be one of your last 10 passwords
      </div>
    </div>
  `,
  styleUrls: ['password-guidelines-tile.component.scss'],
})
export class PasswordGuidelinesTileComponent {
  @Input() showGuidelines: boolean;
}
