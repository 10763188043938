import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducer } from './authentication.reducer';
import { AuthenticationEffects } from './authentication.effects';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    StoreModule.forFeature('authentication', reducer),
    EffectsModule.forFeature([AuthenticationEffects]),
    SharedModule,
  ],
  exports: [],
  declarations: [],
  providers: [],
})
export class AuthenticationStoreModule {}
