import { Component, Input, OnInit } from '@angular/core';
import { ProductAudience } from '../../../../app/core/models/product-audience.enum';
import { Product } from '../../../../app/core/models/product.model';
import { NavigationService } from '../../services/navigation.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'product-tile',
  template: `
    <div class="product-tile" [ngClass]="tileSize">
      <div class="product-content">
        <div class="headline">
          <h3 class="product-name" *ngIf="product.name && product.productId">
            <a
              track-click
              eventName="products-page:product-tile-title:{{ product.name }}"
              *ngIf="product.buttons[0].target !== 'EXTERNAL'"
              [routerLink]="productService.getPath(product.buttons[0].link)"
              [queryParams]="productService.getQueries(product.buttons[0].link)"
            >
              {{ product.name }}
            </a>
            <a
              track-click
              eventName="products-page:product-tile-title:{{ product.name }}"
              *ngIf="product.buttons[0].target === 'EXTERNAL'"
              href="{{ product.buttons[0].link }}"
            >
              {{ product.name }}
            </a>
          </h3>
          <h3
            class="product-name-inactive"
            *ngIf="product.name && !product.productId"
          >
            {{ product.name }}
          </h3>
          <span
            class="privacy-icon"
            [hidden]="!getIcon(product)"
            [ngClass]="getIcon(product)"
            [attr.data-product-name]="product.name"
            [appTooltip]="tooltip"
            (mouseenter)="hoverHandler($event)"
          ></span>
        </div>
        <div
          class="product-description"
          [innerHTML]="product.description | safehtml"
        ></div>
        <div class="links">
          <div class="link-content">
            <a
              track-click
              eventName="products-page:product-tile:/products/{{
                navService.convertToUrl(product.name)
              }}:Overview"
              class="no-decorations"
              routerLink="/products/{{ navService.convertToUrl(product.name) }}"
              [queryParams]="{ id: product.productId }"
            >
              Overview
              <span class="icon-dx-arrow-right red"></span>
            </a>
            <a
              track-click
              eventName="products-page:product-tile:/documentation/{{
                navService.convertToUrl(product.name)
              }}:Docs"
              *ngIf="product.isDocumentationVisible"
              class="no-decorations"
              routerLink="/documentation/{{
                navService.convertToUrl(product.name)
              }}"
              [queryParams]="{ id: product.productId }"
            >
              Docs
              <span class="icon-dx-arrow-right red"></span>
            </a>
            <!-- Only external links come from CMS -->
            <div *ngFor="let link of product.buttons">
              <a
                track-click
                eventName="products-page:product-tile:{{ link.link }}:{{
                  link.text
                }}"
                *ngIf="link.target === 'EXTERNAL'"
                [routerLink]="productService.getPath(link.link)"
                [queryParams]="productService.getQueries(link.link)"
                class="no-decorations"
              >
                {{ link.text }}
                <span class="icon-dx-arrow-right red"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['product-tile.component.scss'],
})
export class ProductTileComponent implements OnInit {
  @Input() product: Product;
  @Input() tileSize: String;

  public productLink: string;
  public experimentalTooltip =
    'This is an experimental API.  Features may change periodically.';
  public experimentalGrantedTooltip =
    'You have access to this experimental API. Features may change periodically.';
  public privateTooltip = 'Full access is restricted to integration partners.';
  public privateGrantedTooltip = 'You have access to this restricted product.';
  public tooltip = this.privateTooltip;

  constructor(
    public productService: ProductService,
    public navService: NavigationService
  ) {}

  ngOnInit() {}
  /**
   * Return audience type
   */
  get audienceType() {
    return ProductAudience;
  }

  public hoverHandler($event) {
    if ($event.target.nodeName === 'SPAN') {
      const iconType = $event.target.className.includes('locked')
        ? 'locked'
        : 'experimental';
      const productName = $event.target.dataset.productName.replace(/\s/g, '');
    }
  }

  /**
   * Get the product audience icon.  product.audienceType is the CMS discretionary
   * field - it tells us the most exclusive section of the product available.
   * returnedAudienceType is the type of section returned by Content Engine.
   */
  public getIcon(product: Product): string {
    if (product && product.audienceType) {
      switch (product.audienceType.toUpperCase()) {
        case ProductAudience.PRIVATE:
          this.tooltip = this.privateTooltip;
          // The product audience (most restrictive audience in this case) equals returned type, they have access
          if (ProductAudience.PRIVATE === product.returnedAudienceType) {
            this.tooltip = this.privateGrantedTooltip;
            return 'unlocked';
          } else {
            return 'locked';
          }
        case ProductAudience.EXCLUSIVE:
          this.tooltip = this.privateTooltip;
          // The product audience (most restrictive audience in this case) equals returned type, they have access
          if (ProductAudience.EXCLUSIVE === product.returnedAudienceType) {
            this.tooltip = this.privateGrantedTooltip;
            return 'unlocked';
          } else {
            return 'locked';
          }
        case ProductAudience.EXPERIMENTAL:
          // Always return experimental if the product is experiments
          this.tooltip = this.experimentalTooltip;
          // The product audience (most restrictive audience in this case) equals returned type, they have access
          if (ProductAudience.PRIVATE === product.returnedAudienceType) {
            this.tooltip = this.experimentalGrantedTooltip;
          }
          return 'experimental';
        default:
          return '';
      }
    }
  }
}
