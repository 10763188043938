import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, timeout } from 'rxjs/operators';

import { FeatureToggleEndpoints as endpoints } from './service-endpoints';
import { timeouts } from '../../../environments/environment';

@Injectable()
export class FeatureToggleService {
  constructor(private httpClient: HttpClient) {}

  getFeatureToggle(featureName: string): Observable<boolean> {
    const url = `${endpoints.GET_FEATURE_TOGGLE}${featureName}`;
    return this.httpClient.get(url).pipe(
      timeout(timeouts.http),
      map(toggle => toggle as boolean)
    );
  }
}
