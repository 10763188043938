import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private loadModal: Subject<any> = new Subject();
  public modal$: Observable<any> = this.loadModal.asObservable();

  showModal(component: any, data?: any) {
    /**
     * We create this object to pass to Subject because Subject can only take one parameter.
     * As we will need to pass data to modals at times (i.e. get access modal), we need to
     * not only pass the component (to be included in entrypoints in the module), but also
     * the data itself.
     */
    const compObj = {
      component: component,
      data: data,
    };
    this.loadModal.next(compObj);
  }
}
