import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { AppMetrics } from '../../core/models/app-metrics.model';
import { App } from '../../core/models/app.model';
import { StoreStates } from '../../core/models/store-states.enum';

export enum DashboardActionTypes {
  ShowUserPortraitAction = '[Dashboard] Show User Portrait',
  LoadAppsAction = '[Dashboard] Load Apps',
  StoreAppsAction = '[Dashboard] Store Apps',
  LoadAppIconAction = '[Dashboard] Load App Icon',
  StoreAppIconAction = '[Dashboard] Store App Icon',
  LoadAppOverviewMetricsAction = '[Dashboard] Load App Overview Metrics',
  StoreAppOverviewMetricsAction = '[Dashboard] Store App Overview Metrics',
  StoreAppOverviewMetricsErrorAction = '[Dashboard] Store App Overview Metrics Error',
  LoadAppInfoAction = '[Dashboard] Load App Info',
  StoreAppInfoAction = '[Dashboard] Store App Info',
  StoreAppInfoErrorAction = '[Dashboard] Store App Info Error',
  // Other
  RegisterAppAction = '[Dashboard] Register App',
  StoreRegisterAppAction = '[Dashboard] Register App Response',
  UpdateEnvironmentsAvailableAction = '[Dashboard] Update Environments Available',
}

export class ShowUserPortrait implements Action {
  readonly type = DashboardActionTypes.ShowUserPortraitAction;

  constructor(public payload: { portraitVisible: boolean }) {}
}

export class LoadApps implements Action {
  readonly type = DashboardActionTypes.LoadAppsAction;
}

export class StoreApps implements Action {
  readonly type = DashboardActionTypes.StoreAppsAction;

  constructor(public payload: { apps: App[]; appsLoading: StoreStates }) {}
}

export class LoadAppIcon implements Action {
  readonly type = DashboardActionTypes.LoadAppIconAction;

  constructor(public payload: { appId: string }) {}
}

export class StoreAppIcon implements Action {
  readonly type = DashboardActionTypes.StoreAppIconAction;

  constructor(public payload: { app: Update<App> }) {}
}

export class LoadAppInfo implements Action {
  readonly type = DashboardActionTypes.LoadAppInfoAction;

  constructor(public payload: { appId: string }) {}
}

export class StoreAppInfo implements Action {
  readonly type = DashboardActionTypes.StoreAppInfoAction;

  constructor(
    public payload: {
      app: App;
      overviewMetrics: AppMetrics[];
      metrics: AppMetrics[];
    }
  ) {}
}

export class StoreAppInfoError implements Action {
  readonly type = DashboardActionTypes.StoreAppInfoErrorAction;
}

export class LoadAppOverviewMetrics implements Action {
  readonly type = DashboardActionTypes.LoadAppOverviewMetricsAction;

  constructor(
    public payload: {
      appId: string;
      appClientId: string;
      environment: string;
      duration: string;
      frequency: string;
      consumptionDomain: string;
      timezone: string;
    }
  ) {}
}

export class StoreAppOverviewMetrics implements Action {
  readonly type = DashboardActionTypes.StoreAppOverviewMetricsAction;

  constructor(public payload: { overviewMetrics: Update<AppMetrics> }) {}
}

export class StoreAppOverviewMetricsError implements Action {
  readonly type = DashboardActionTypes.StoreAppOverviewMetricsErrorAction;

  constructor(public payload: { overviewMetrics: Update<AppMetrics> }) {}
}

export class RegisterApp implements Action {
  readonly type = DashboardActionTypes.RegisterAppAction;

  constructor(
    public payload: {
      name: string;
      description: string;
      image: string;
      appType: string;
    }
  ) {}
}

export class StoreRegisterApp implements Action {
  readonly type = DashboardActionTypes.StoreRegisterAppAction;

  constructor(public payload: { isSuccess: boolean; appId: string }) {}
}

export class UpdateEnvironmentsAvailable implements Action {
  readonly type = DashboardActionTypes.UpdateEnvironmentsAvailableAction;

  constructor(
    public payload: { itAvailable: boolean; productionAvailable: boolean }
  ) {}
}

export type DashboardActions =
  | ShowUserPortrait
  | LoadApps
  | LoadAppInfo
  | StoreAppInfo
  | StoreAppInfoError
  | StoreApps
  | LoadAppIcon
  | StoreAppIcon
  | LoadAppOverviewMetrics
  | StoreAppOverviewMetrics
  | StoreAppOverviewMetricsError
  | RegisterApp
  | UpdateEnvironmentsAvailable
  | StoreRegisterApp;
