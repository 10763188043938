import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { timeouts } from '../../../environments/environment';
import { Section } from '../model/content.model';
import { ContentManagementEndpoints as endpoints } from './service-endpoints';
import { environment } from 'src/environments/environment';
import { AxiosRequestConfig } from 'axios';
import { CaliperX2Service } from './caliperx2.service';

@Injectable()
export class ContentService {
  constructor(private caliperX2Service: CaliperX2Service) {}

  getTermsAndConditionsContent(
    url: string,
    contentSelector: any,
    usingCaas2: boolean
  ): Observable<Section[]> {
    let publicSectionData: Section[] = [];
    url = endpoints.CONTENT_ENGINE_SEARCH + url;
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
    if (usingCaas2) {
      const params = { domain: 'devexchange' };
      url = endpoints.CONTENT_ENGINE_SEARCH;
      const requestConfig: AxiosRequestConfig = {
        url,
        method: 'POST',
        headers,
        params,
        data: contentSelector,
      };
      return from(this.caliperX2Service.gatewayRequest(requestConfig)).pipe(
        timeout(timeouts.http),
        map(res => (res as any).data.documents[0]),
        map(document => {
          const section = new Section();
          const rawSectionData =
            document.documentStructure.documentStructurePayload.entry
              .contentCollection[0].sections;
          if (rawSectionData[0] && rawSectionData[0].items[0]) {
            section.headline = rawSectionData[0].items[0].value.default;
          }
          if (rawSectionData[1] && rawSectionData[1].items[0]) {
            section.body = [
              { content: rawSectionData[1].items[0].value.default },
            ];
          }
          publicSectionData.push(section);
          return publicSectionData;
        })
      );
    }

    const requestConfig: AxiosRequestConfig = {
      url,
      method: 'GET',
      headers,
      data: {},
    };
    return from(this.caliperX2Service.gatewayRequest(requestConfig)).pipe(
      timeout(timeouts.http),
      map(res => (res as any).data.article),
      map(article => {
        publicSectionData = article[contentSelector].section;
        return publicSectionData;
      })
    );
  }

  getOurProcessContent(
    url: string,
    contentSelector: any,
    usingCaas2: boolean
  ): Observable<Section[]> {
    let publicSectionData: Section[] = [];
    url = endpoints.CONTENT_ENGINE_SEARCH + url;
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
    if (usingCaas2) {
      const params = { domain: 'devexchange' };
      url = endpoints.CONTENT_ENGINE_SEARCH;
      const requestConfig: AxiosRequestConfig = {
        url,
        method: 'POST',
        headers,
        params,
        data: contentSelector,
      };
      return from(this.caliperX2Service.gatewayRequest(requestConfig)).pipe(
        timeout(timeouts.http),
        map(res => (res as any).data.documents[0]),
        map(document => {
          document.documentStructure.documentStructurePayload.entry.contentCollection.forEach(
            contentCollection => {
              contentCollection.sections.forEach(sectionData => {
                const section = new Section();
                if (sectionData.name.includes('Image')) {
                  section.headline = sectionData.items[0].name;
                  section.body = [
                    {
                      content:
                        environment.assetsCdn +
                        '/devexchange' +
                        document.documentStructure.documentStructurePayload
                          .references[sectionData.items[0].value],
                    },
                  ];
                } else {
                  section.headline = sectionData.items[0]?.value.default;
                  section.body = [
                    { content: sectionData.items[1]?.value.default },
                  ];
                }
                publicSectionData.push(section);
              });
            }
          );
          return publicSectionData;
        })
      );
    }

    const requestConfig: AxiosRequestConfig = {
      url,
      method: 'GET',
      headers,
      data: {},
    };
    return from(this.caliperX2Service.gatewayRequest(requestConfig)).pipe(
      timeout(timeouts.http),
      map(res => (res as any).data.article),
      map(article => {
        publicSectionData = article[contentSelector].section;
        return publicSectionData;
      })
    );
  }
}
