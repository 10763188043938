import { Directive, ElementRef, forwardRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'dxo-ui-textfield, dxo-ui-textarea, dxo-ui-select',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WebComponentDirective),
      multi: true,
    },
  ],
})
export class WebComponentDirective implements ControlValueAccessor {
  constructor(private elementRef: ElementRef) {}

  get value() {
    return this._value;
  }

  set value(val) {
    if (val !== this._value) {
      this._value = val;
      this.onChange(this._value);
      this.onTouched();
      this.elementRef.nativeElement.value = val;
    }
  }

  private _value: number;

  onChange: any = () => {};
  onTouched: any = () => {};

  @HostListener('change', ['$event.detail'])
  listenForChange(detail) {
    this.value = detail.payload.value;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
