import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SlateComponent } from './slate.component';
import { DashboardSlateComponent } from '../dashboard-slate/dashboard-slate.component';
import { RouterModule } from '@angular/router';
@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [SlateComponent, DashboardSlateComponent],
  declarations: [SlateComponent, DashboardSlateComponent],
  providers: [],
})
export class SlateModule {}
