import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../services/analytics.service';
import { RouterHistoryService } from '../../services/router-history.service';

@Component({
  selector: 'relogin-page',
  template: `
    <section id="relogin-page">
      <div id="relogin-page-img">
        <img src="assets/img/clip-bad-gateway.png" alt="Relogin Img" />
      </div>
      <div id="relogin-page-title">Sign in with your new password</div>
      <div id="relogin-page-subtitle">
        Since your password was reset, you'll need to sign in again using the
        new one.
        <br />
        Didn't reset your password?
        <a id="contact-us-relogin-page" (click)="contactUs()"
          ><u>Contact us</u></a
        >
        for help.
      </div>

      <div id="relogin-page-btns">
        <button class="outline" (click)="signIn()">Sign In</button>
      </div>
    </section>
  `,
  styleUrls: ['./error-page.component.scss'],
})
export class ReloginComponent implements OnInit {
  previousUrlViaRouterHistoryService$ = this.routerHistoryService.previousUrl$;

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
    private routerHistoryService: RouterHistoryService
  ) {}

  ngOnInit() {
    const previous = this.previousUrlViaRouterHistoryService$.getValue();
    this.analyticsService.pubSubEvent({
      name: `application_submit_failure:${this.constructor.name}:previous-url-${previous}`,
    });
  }

  public signIn() {
    this.router.navigate(['/sign-in']);
  }

  public contactUs() {
    this.router.navigate(['/help/contact-us']);
  }
}
