import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ErrorOverlayComponent } from './components/error-overlay/error-overlay.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ResourceTileComponent } from './components/resource-tile/resource-tile.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { WarningComponent } from './components/warning/warning.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { TrackClickDirective } from './directives/trackclick.directive';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ReloginComponent } from './pages/error-page/relogin-page.component';
import { RemoveConnectedProductsPipe } from './pipes/remove-connected-products';
import { RemoveDependentProductsPipe } from './pipes/remove-dependent-products';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { VersionProductNamesPipe } from './pipes/version-product-names.pipe';
import { AppsService } from './services/apps.service';
import { ContentService } from './services/content.service';
import { MetricsService } from './services/metrics.service';
import { AnalyticsService } from './services/analytics.service';
import { ProductService } from './services/product.service';
import { SignUpService } from './services/sign-up.service';
import { ProductTileComponent } from './components/product-tile/product-tile.component';
import { NotEmptyValidator } from './directives/notEmptyValidator.directive';
import { AuthenticationService } from './services/authentication.service';
import { SalesforceService } from './services/salesforce.service';
import { SignInResultComponent } from '../../app/modules/sign-in/pages/sign-in/sign-in-result.component';
import { WebComponentDirective } from './directives/webcomponent.directive';

const routes: Routes = [
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: 'result',
    component: SignInResultComponent,
  },
  {
    path: 'relogin',
    component: ReloginComponent,
  },
];

@NgModule({
  imports: [CommonModule, RouterModule, RouterModule.forChild(routes)],

  providers: [
    AppsService,
    MetricsService,
    AnalyticsService,
    ProductService,
    SignUpService,
    ProductService,
    AuthenticationService,
  ],
  exports: [
    BreadcrumbsComponent,
    SearchPipe,
    SortPipe,
    VersionProductNamesPipe,
    RemoveDependentProductsPipe,
    RemoveConnectedProductsPipe,
    TooltipDirective,
    WebComponentDirective,
    TrackClickDirective,
    PaginationComponent,
    NotEmptyValidator,
    SafeHtmlPipe,
    WarningComponent,
    PaginationComponent,
    SpinnerComponent,
    ErrorOverlayComponent,
    ResourceTileComponent,
    ProductTileComponent,
  ],
  declarations: [
    BreadcrumbsComponent,
    SearchPipe,
    SortPipe,
    RemoveDependentProductsPipe,
    RemoveConnectedProductsPipe,
    VersionProductNamesPipe,
    TooltipDirective,
    WebComponentDirective,
    TrackClickDirective,
    NotEmptyValidator,
    PaginationComponent,
    SafeHtmlPipe,
    WarningComponent,
    PaginationComponent,
    SpinnerComponent,
    ResourceTileComponent,
    ErrorPageComponent,
    ErrorOverlayComponent,
    ProductTileComponent,
    SignInResultComponent,
    ReloginComponent,
  ],
})
export class SharedModule {}
