import {
  UserSettingsActionTypes,
  UserSettingsActions,
} from './user-settings.actions';

export interface UserSettingsState {
  portrait: string;
}

export const initialState: UserSettingsState = {
  portrait: localStorage.getItem('portrait')
    ? localStorage.getItem('portrait')
    : null,
};

export function userSettingsReducer(
  state: UserSettingsState = initialState,
  action: UserSettingsActions
): UserSettingsState {
  switch (action.type) {
    case UserSettingsActionTypes.PortraitLoadAction:
      break;
    case UserSettingsActionTypes.PortraitUploadAction:
      break;
    case UserSettingsActionTypes.PortraitStoreAction:
      localStorage.setItem('portrait', action.payload.portrait);
      return {
        ...state,
        portrait: action.payload.portrait,
      };
    default:
      return state;
  }
}
