import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SalesforceEndpoints as endpoints } from '../../shared/services/service-endpoints';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Lead } from 'src/app/shared/model/lead.interface';

@Injectable()
export class SalesforceService {
  constructor(
    private httpClient: HttpClient,
    private analyticsService: AnalyticsService
  ) {}

  /**
   * Creates a new contact us lead
   */
  createLead(lead: Lead): Observable<any> {
    return this.httpClient
      .post(endpoints.SALESFORCE_RESOURCE_CREATE_LEAD_PATH, lead)
      .pipe(
        map(resp => resp),
        catchError(error => {
          this.handleError(error, 'createLead');
          throw error;
        })
      );
  }

  /**
   * Here, we're just handling tracking the error.  We still want to
   * propagate the error to the effect or component, which gives us the flexibility
   * of easily implementing or removing ngrx without completely refactoring our
   * services.
   * @param error the error
   */
  private handleError(error: any, methodName?: string) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.analyticsService.pubSubEvent({
      name: `application_submit_failure:${this.constructor.name}:error-${methodName}-${errorMessage}`,
    });
    return of(null);
  }
}
