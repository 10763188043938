import { Component, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, combineLatest } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { ModalService } from '../../modal/modal.service';
import { ChangePasswordComponent } from '../../modal/change-password/change-password.component';
import { CommonConstants } from '../../common.constants';
import {
  selectAuthenticationStatus,
  selectUser,
} from 'src/app/store/authentication/authentication.selectors';
import { AlertBannerComponent } from 'src/app/shared/alert-banner/alert-banner.component';

@Component({
  selector: 'global-alerts',
  templateUrl: 'global-alerts.component.html',
  styleUrls: ['global-alerts.component.scss'],
})
export class GlobalAlertsComponent implements OnInit, OnDestroy {
  constructor(private store: Store<any>, private modalService: ModalService) {}
  @Output() alertVisible: Subject<boolean> = new Subject();
  @ViewChild('alertBanner') alertBanner: AlertBannerComponent;

  public passwordExpirationMessage = '';
  public passwordExpiryAlertVisible = false;

  // Observable to detect mouse movements, is throttled below
  private _disableInactivityListener = new Subject();
  private _user$: Observable<User>;
  private _authenticated$: Observable<boolean>;
  private _destroyed: Subject<void> = new Subject();

  ngOnInit() {
    // Creating a variable to store the isAuthenticated selector.
    this._authenticated$ = this.store.pipe(
      select(selectAuthenticationStatus),
      takeUntil(this._destroyed)
    );
    // Creating a variable to store the user selector.
    this._user$ = this.store.pipe(
      select(selectUser),
      takeUntil(this._destroyed)
    );

    this.setupPasswordExpiration();
  }

  ngOnDestroy() {
    this._disableInactivityListener.next();
    this._disableInactivityListener.complete();
    this._destroyed.next();
    this._destroyed.complete();
  }

  /**
   * Opens the ChangePassword Modal.
   */
  public openChangePasswordModal() {
    this.modalService.showModal(ChangePasswordComponent);
  }

  /**
   * A function to store all the setup and variables required for the password expiration banner.
   * This is in its own function because we could have multiple banners in the future.
   */
  private setupPasswordExpiration() {
    combineLatest(this._authenticated$, this._user$)
      .pipe(
        takeUntil(this._destroyed),
        // Make sure the user has been authenticated and has a user object created.
        filter(([authenticated, user]) => !!user)
      )
      .subscribe(([authenticated, user]) => {
        if (
          user.developerType &&
          user.developerType.indexOf('SSO_ENABLED') !== -1
        ) {
          //do not show password banner for sso logged in user
          this.passwordExpiryAlertVisible = false;
          this.alertVisible.next(false);
        } else {
          const daysSinceLastPassUpdate = this.getDaysSinceLastPassUpdate(
            user.lastPassUpdate
          );
          const daysUntilPasswordExpiry =
            CommonConstants.PASS_LOCK_ON_EXPIRY_PERIOD -
            daysSinceLastPassUpdate;
          // If the password was updated 76 days or more ago
          if (
            daysUntilPasswordExpiry <=
              CommonConstants.PASS_EXPIRY_BANNER_START_DAYS &&
            authenticated
          ) {
            if (daysUntilPasswordExpiry <= 1) {
              this.passwordExpirationMessage = `Your password will expire soon.`;
            } else {
              this.passwordExpirationMessage = `Your password will expire in ${daysUntilPasswordExpiry} days.`;
            }
            // Toggle the banner and emit to our parent that we have a visible banner.
            this.passwordExpiryAlertVisible = true;
            this.alertVisible.next(true);
          } else {
            // If the password was changed within the appropriate amount of time, hide the modal.
            this.passwordExpiryAlertVisible = false;
            this.alertVisible.next(false);
          }
        }
      });
  }

  /**
   * Calculates the number of days since the password was last updated.
   * @param lastPassUpdate: number - The last time the password was updated, in milliseconds from Date().
   */
  private getDaysSinceLastPassUpdate(lastPassUpdate: number): number {
    if (!lastPassUpdate) {
      return -1;
    }
    let passExpiryMillis;
    if (isNaN(lastPassUpdate)) {
      passExpiryMillis = Date.now() - new Date(lastPassUpdate).valueOf();
    } else {
      passExpiryMillis = Date.now() - lastPassUpdate;
    }
    const passExpiryDays = Math.floor(passExpiryMillis / (1000 * 60 * 60 * 24));
    return passExpiryDays;
  }

  updateBanner(bannerOpen) {
    if (!bannerOpen) {
      // if the alert banner is closed, tell parent it's no longer visible
      this.alertVisible.next(false);
    }
  }
}
