import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
  selectAuthenticationLoadedStatus,
  selectAuthenticationStatus,
} from '../../store/authentication/authentication.selectors';
import { AppState } from '../reducers';
import { StoreStates } from '../models/store-states.enum';

@Injectable({ providedIn: 'root' })
export class AuthenticatedGuard implements CanActivate {
  caliperx = (window as any).caliperx;
  constructor(private store: Store<AppState>) {}

  private timeout$ = timer(5000);

  canActivate(): Observable<boolean> {
    return this.store.pipe(select(selectAuthenticationLoadedStatus)).pipe(
      filter(loaded => loaded !== StoreStates.UNKNOWN),
      first(),
      switchMap(loaded => {
        if (loaded) {
          return this.store
            .pipe(select(selectAuthenticationStatus), takeUntil(this.timeout$))
            .pipe(
              map(authenticated => {
                if (authenticated) {
                  return true;
                } else {
                  this.caliperx.services.authenticateAndReturn();
                  return false;
                }
              })
            );
        }
        return of(false);
      })
    );
  }
}
