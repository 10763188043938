import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'otp-contact-us',
  templateUrl: 'otp-contact-us.component.html',
  styleUrls: ['otp-contact-us.component.scss'],
})
export class OTPContactUsComponent {
  @Input() data;
  @Output() close: EventEmitter<void> = new EventEmitter();

  closeModal() {
    this.close.emit();
  }
}
