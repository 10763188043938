import { Action } from '@ngrx/store';

export enum UserSettingsActionTypes {
  PortraitStoreAction = '[UserSettings] Store User Portrait',
  PortraitLoadAction = '[UserSettings] Load User Portrait',
  PortraitUploadAction = '[UserSettings] Upload User Portrait',
}

/** Retrieve user portrait from server */
export class PortraitLoad implements Action {
  readonly type = UserSettingsActionTypes.PortraitLoadAction;
}

/** Store user portrait in local store */
export class PortraitStore implements Action {
  readonly type = UserSettingsActionTypes.PortraitStoreAction;
  constructor(public payload: { portrait: string }) {}
}

/** Upload new user portrait to server */
export class PortraitUpload implements Action {
  readonly type = UserSettingsActionTypes.PortraitUploadAction;
  constructor(public payload: { portrait: string }) {}
}

export type UserSettingsActions = PortraitUpload | PortraitStore | PortraitLoad;
