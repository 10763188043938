import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';

import * as authenticationActions from './authentication.actions';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { BrowserStorage } from 'src/app/core/models/browser-storage.enum';
import { CaliperX2Service } from 'src/app/shared/services/caliperx2.service';

@Injectable()
export class AuthenticationEffects {
  private caliperX2Service = inject(CaliperX2Service);

  checkAuthenticationState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.checkAuthenticationState),
      switchMap(() =>
        this.caliperX2Service
          .getAuthenticated()
          .pipe(
            map(isAuthenticated =>
              isAuthenticated
                ? authenticationActions.setAuthenticatedState()
                : authenticationActions.setUnauthenticatedState()
            )
          )
      )
    )
  );

  clearSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authenticationActions.clearSession),
        map(action =>
          this.authenticationService.endSession(action.logoutAction).subscribe()
        ),
        tap(() => {
          /** Local storage */
          localStorage.clear();
          /** Session storage */
          sessionStorage.removeItem(BrowserStorage.STATE);
          sessionStorage.removeItem(BrowserStorage.STATE_NONCE);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService
  ) {}
}
