import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { User } from 'src/app/core/models/user.model';

/* Team Member Entities */
export function selectEmail(t: User): string {
  return t.email;
}

export function sortByEmail(a: User, b: User): number {
  return a.email.localeCompare(b.email);
}

export const teamMemberAdapter: EntityAdapter<User> = createEntityAdapter<User>(
  {
    selectId: selectEmail,
    sortComparer: sortByEmail,
  }
);

export interface TeamMemberState extends EntityState<User> {}
