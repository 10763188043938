import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/core/models/user.model';
import { StepUp } from 'src/app/core/models/step-up.model';
import { LogoutAction } from 'src/app/core/models/logout-action.enum';

export const checkAuthenticationState = createAction(
  '[Main] Check Authentication State'
);

export const setAuthenticatedState = createAction(
  '[API] Set Authenticated State'
);

export const setUnauthenticatedState = createAction(
  '[API] Set Unauthenticated State'
);

export const clearSession = createAction(
  '[API] Clear Session',
  props<{ logoutAction?: LogoutAction }>()
);

export const getUserDetails = createAction('[API] Get User Details');

export const storeUserDetails = createAction(
  '[Effect] Store User Details',
  props<{ user: User }>()
);

export const updateLastPassUpdate = createAction(
  '[Action] Update Last Pass Update',
  props<{ lastPassUpdate: number }>()
);

export const stepUpAuthentication = createAction(
  '[Action] Step Up Authentication',
  props<{ stepUpData: StepUp }>()
);
