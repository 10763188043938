import { Injectable } from '@angular/core';
import { CaliperX } from '../model/caliperx.model';
import { AxiosPromise, AxiosRequestConfig } from 'axios';

declare const caliperx: CaliperX;

@Injectable({
  providedIn: 'root',
})
export class CaliperXService {
  gatewayRequest(config: AxiosRequestConfig): AxiosPromise {
    return caliperx.client.sdk.gateway.request(config);
  }
}
