<section id="team-member-access-roles-modal">
  <span
    role="button"
    tabindex="0"
    id="close-modal"
    class="icon-dx-close"
    aria-label="Close modal"
    (click)="closeModal()"
  ></span>
  <div id="content-container">
    <img
      alt="Security Icon"
      class="header-image"
      src="assets/svg/iconSecurity.svg"
    />
    <h2>Team Member Access Roles</h2>
    <div class="section-group">
      <div class="section-row section-row-header">
        <p></p>
        <h3>OWNER</h3>
        <h3>ADMIN</h3>
        <h3>DEVELOPER</h3>
      </div>
      <div class="section-row">
        <p>View Connected APIs</p>
        <img alt="check" src="assets/svg/check.svg" />
        <img alt="check" src="assets/svg/check.svg" />
        <img alt="check" src="assets/svg/check.svg" />
      </div>
      <div class="section-row">
        <p>View sandbox credentials</p>
        <img alt="check" src="assets/svg/check.svg" />
        <img alt="check" src="assets/svg/check.svg" />
        <img alt="check" src="assets/svg/check.svg" />
      </div>
      <div class="section-row">
        <p>View analytics</p>
        <img alt="check" src="assets/svg/check.svg" />
        <img alt="check" src="assets/svg/check.svg" />
        <img alt="check" src="assets/svg/check.svg" />
      </div>
      <div class="section-row">
        <p>Connect API’s (sandbox only)</p>
        <img alt="check" src="assets/svg/check.svg" />
        <img alt="check" src="assets/svg/check.svg" />
      </div>
      <div class="section-row">
        <p>Add and remove developer team members</p>
        <img alt="check" src="assets/svg/check.svg" />
        <img alt="check" src="assets/svg/check.svg" />
      </div>
      <div class="section-row">
        <p>Create and delete app</p>
        <img alt="check" src="assets/svg/check.svg" />
      </div>
      <div class="section-row">
        <p>Edit sandbox app details</p>
        <img alt="check" src="assets/svg/check.svg" />
      </div>
      <div class="section-row">
        <p>Request production access</p>
        <img alt="check" src="assets/svg/check.svg" />
      </div>
      <div class="section-row">
        <p>View production credential</p>
        <img alt="check" src="assets/svg/check.svg" />
      </div>
      <div class="section-row">
        <p>Add and remove team members (all roles)</p>
        <img alt="check" src="assets/svg/check.svg" />
      </div>
    </div>
    <div class="footnote">
      <p>
        It's a good idea to have 2-4 team members with the Owner role. Keep in
        mind - changes in ownership may require client secret rotation.
      </p>
      <p>Capital One associates can't be owners in production.</p>
    </div>
    <dxo-ui-button
      id="close-button"
      name="close-button"
      text="Close"
      (click)="closeModal()"
    >
    </dxo-ui-button>
  </div>
</section>
