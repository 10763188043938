/**
 * The UI doesn't handle or care if the product is in the PRECDE/CDE environments.  Instead,
 * this is all handled in the backend.  For the UI, we will place PRECDE in IT and CDE in Production,
 * as that's how it's displayed.  For connect/disconnect of products, we'll pass the isPCIProduct flag,
 * which will tell the backend where to place or look for the product information.
 */
export const PciEnvMapping = {
  PRECDE: 'IT',
  CDE: 'PRODUCTION'
};
