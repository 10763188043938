import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LinkType } from '../model/link-type.enum';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router, private route: ActivatedRoute) {}

  /**
   * Navigates to url based on whether or not the url is external or not
   * @param url url to navigate to
   * @param type type of url - EXTERNAL or INTERNAL
   */
  public navigateToUrl(url: string, type?: LinkType) {
    // Navigate to external site - must start with http or https
    if (
      (type && type.toUpperCase() === LinkType.EXTERNAL) ||
      !url.startsWith('/')
    ) {
      window.location.href = url;
    } else {
      // Internal url
      this.router.navigate([url], { queryParamsHandling: 'merge' });
    }
  }

  /**
   * Converts a string to all lowercase, replaces spaces with -, and trims
   * @param val Value to be converted
   * @returns string ready to be used in a url
   */
  public convertToUrl(val: string): string {
    return val
      .replace(/\s+/g, '-')
      .toLowerCase()
      .trim();
  }

  // Remove characters that would break the call to query by discretionary value
  public cleanCmsDiscretionaryValue(val: string): string {
    // Remove dashes for now
    return val.replace(/[|;$%@"<>()+,-]/g, '');
  }

  // For table of contents objects - converting the path to the discretion
  public convertPathToDiscretion(path) {
    let discretion = path
      .split('-')
      .map(x => x.charAt(0).toUpperCase() + x.slice(1))
      .join('');
    discretion = this.cleanCmsDiscretionaryValue(discretion);
    return discretion;
  }

  /**
   * If the URL doesn't match as far as the expected product name,
   * fix it using the content coming from CMS for the product.  Replace the actual state
   * so it is recorded in the router history.
   */
  public fixUrl(baseUrl: string, path: string, queryParams: Params) {
    // Remove leading/trailing slashes so the url can be better resilient to varying input
    const formattedBaseUrl = baseUrl.replace(/^\/|\/$/g, '');

    // Get current url
    let url = `/${formattedBaseUrl}/${this.convertToUrl(path)}`;

    // Deal with query params - replace if any were added
    const queries = queryParams ? queryParams : this.route.snapshot.queryParams;
    let params = new HttpParams();
    for (const [key, value] of Object.entries(queries)) {
      params = params.set(key, value);
    }
    if (Object.entries(queries)) {
      url = url + '?' + params.toString();
    }

    // Add fragment back to url if it exists
    const fragment = this.route.snapshot.fragment;
    if (fragment) {
      url = url + '#' + fragment;
    }

    // Navigate and replace history with current url
    this.router.navigateByUrl(url, { replaceUrl: true });
  }
}
