import { Injectable } from '@angular/core';
import { StateObject } from '../models/state-object.model';
import * as CryptoJS from 'crypto-js';
import { AuthenticationType } from '../models/authentication-type.enum';
import { v4 as uuidv4 } from 'uuid';
import { BrowserStorage } from '../models/browser-storage.enum';
const styling = 'font-weight: 800; font-size: 12px; color: blue';
@Injectable()
export class StateService {
  validateReturnToPath(path: string): boolean {
    // Regex to disallow backslashes, encoded periods (%2E), encoded slashes (%2F),
    // and ensure that no additional forward slashes or backslashes are present beyond the initial one.
    const relativePathRegex = /^\/(?!\/|.*%2E|.*%2F|.*\\)([^\/.\s%]+\/?)*$/i;
    const isValid = relativePathRegex.test(path);
    console.log(`%creturnTo path "${path}" is valid:`, styling, isValid);
    return isValid;
  }

  /** Gets the appropriate state object based on the path provided */
  public generateAuthenticationStateObj(
    authenticationAction: AuthenticationType,
    passedPath?: string
  ): StateObject {
    let path = '/dashboard';
    if (passedPath) {
      path = passedPath;
    }
    return {
      authenticationAction: authenticationAction,
      returnToPath: path,
    };
  }

  /** Compares the recieved state to the stored state */
  public validateState(recievedState: string): boolean {
    if (this.getState() === recievedState) {
      console.log('%cstate is valid', styling);
      return true;
    }
    console.log('%cstate is not valid', styling);
    return false;
  }

  public generateAndReturnState(stateObj: StateObject) {
    // Generate the state value and send it with the refresh request
    this.generateAndStoreStateInfo(stateObj);
    const state = this.getState();
    return state;
  }

  /** Generate and store the state information */
  public generateAndStoreStateInfo(stateObj: StateObject) {
    const nonce = this.generateNonce();
    const state = this.generateState(stateObj, nonce);

    /** Set state */
    sessionStorage.setItem(BrowserStorage.STATE, state);

    /** Set state nonce */
    sessionStorage.setItem(BrowserStorage.STATE_NONCE, nonce);
  }

  /** Retrieve the state from session storage */
  public getState() {
    const state = sessionStorage.getItem(BrowserStorage.STATE);
    console.log('%csession storage state', styling, state);
    return state;
  }

  /** Retrieve the state nonce from session storage */
  public retrieveStateNonce() {
    const stateNonce = sessionStorage.getItem(BrowserStorage.STATE_NONCE);
    console.log('%csession storage nonce:', styling, stateNonce);
    return stateNonce;
  }

  /** Parse the state object */
  public parseState(state: string, nonce: string): StateObject {
    let parsedState: StateObject;
    parsedState = this.decryptState(state, nonce);
    return parsedState;
  }

  /** Removes state from session storage */
  public clearState() {
    sessionStorage.removeItem(BrowserStorage.STATE);
    sessionStorage.removeItem(BrowserStorage.STATE_NONCE);
  }

  /** Encrypt and Base64 encode */
  public encryptState(message: string, nonce: string) {
    /** Encrypt the message */
    const encryptedMessage = CryptoJS.AES.encrypt(message, nonce).toString();

    /** Base64 encode and return */
    return btoa(encryptedMessage);
  }

  /** Base64 decode and decrypt */
  public decryptState(message: string, nonce: string): StateObject {
    /** Base64 Decode */
    const messageStr = atob(decodeURIComponent(message));

    /** Decrypt the state object */
    const encryptedBytes = CryptoJS.AES.decrypt(messageStr, nonce);
    const decryptedObject = JSON.parse(
      encryptedBytes.toString(CryptoJS.enc.Utf8)
    );
    return decryptedObject;
  }

  /** Generates an encrypted state */
  private generateState(stateObj: StateObject, nonce: string): string {
    const stateStr = JSON.stringify(stateObj);
    const state = this.encryptState(stateStr, nonce);
    return state;
  }

  /** Generate nonce for encryption */
  private generateNonce(): string {
    const nonce = uuidv4();
    return nonce;
  }
}
