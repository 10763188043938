import { inject, Injectable } from '@angular/core';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { WINDOW } from '../../app.constants';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CaliperX2Service {
  window = inject(WINDOW);
  caliperx = this.window.caliperx;
  caliperx2 = this.window.caliperx2;

  isCaliperX2 = typeof this.caliperx2 !== 'undefined' && this.caliperx2;

  getAuthenticated(): Observable<boolean> {
    if (this.isCaliperX2) {
      return (
        this.caliperx2?.authentication?.state?.isAuthenticated ?? of(false)
      );
    } else {
      return of(!!this.caliperx?.accountInfo?.authenticated);
    }
  }

  logOut(): void {
    if (this.isCaliperX2) {
      this.caliperx2.authentication.functions.logOut();
    } else {
      this.caliperx.services.logOut();
    }
  }

  authenticateAndReturn(): boolean {
    if (this.isCaliperX2) {
      return this.caliperx2.authentication.functions.authenticateAndReturn();
    } else {
      return this.caliperx.services.authenticateAndReturn();
    }
  }

  potomacPageView(): void {
    if (this.isCaliperX2) {
      this.caliperx2.potomac.pageView();
    } else {
      this.caliperx.services.potomac.pageView();
    }
  }

  potomacInteraction(eventData: string): void {
    if (this.isCaliperX2) {
      return this.caliperx2.potomac.interaction(
        'default',
        'partner-portal-element',
        eventData
      );
    } else {
      return this.caliperx.services.potomac.interaction(
        'default',
        'partner-portal-element',
        eventData
      );
    }
  }

  gatewayRequest(config: AxiosRequestConfig): AxiosPromise {
    if (this.isCaliperX2) {
      return this.caliperx2.gateway.request(config);
    } else {
      return this.caliperx.client.sdk.gateway.request(config);
    }
  }
}
