import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (location == null || window.location.origin.includes('localhost')) {
      window.location.href = '/documentation'; // Going to documentation page since that is in dxo-ui
      return false;
    }
    window.location.href = route.data['externalUrl'];
    return true;
  }
}
