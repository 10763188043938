import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { timeouts } from '../../../environments/environment';
import { Section } from '../model/content.model';
import { ContentManagementEndpoints as endpoints } from './service-endpoints';
import { environment } from 'src/environments/environment';

@Injectable()
export class ContentService {
  constructor(private httpClient: HttpClient) {}

  getTermsAndConditionsContent(
    url: string,
    contentSelector: any,
    usingCaas2: boolean
  ): Observable<Section[]> {
    let publicSectionData: Section[] = [];
    url = endpoints.CONTENT_ENGINE_SEARCH + url;
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );
    if (usingCaas2) {
      const params: HttpParams = new HttpParams().append(
        'domain',
        'devexchange'
      );
      url = endpoints.CONTENT_ENGINE_SEARCH;
      return this.httpClient
        .post(url, contentSelector, { params, headers })
        .pipe(
          timeout(timeouts.http),
          map(res => (res as any).documents[0]),
          map(document => {
            const section = new Section();
            const rawSectionData =
              document.documentStructure.documentStructurePayload.entry
                .contentCollection[0].sections;
            section.headline = rawSectionData[0].items[0].value.default;
            section.body = [
              { content: rawSectionData[1].items[0].value.default },
            ];
            publicSectionData.push(section);
            return publicSectionData;
          })
        );
    }

    return this.httpClient.get(url, { headers }).pipe(
      timeout(timeouts.http),
      map(res => (res as any).article),
      map(article => {
        publicSectionData = article[contentSelector].section;
        return publicSectionData;
      })
    );
  }

  getOurProcessContent(
    url: string,
    contentSelector: any,
    usingCaas2: boolean
  ): Observable<Section[]> {
    let publicSectionData: Section[] = [];
    url = endpoints.CONTENT_ENGINE_SEARCH + url;
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );
    if (usingCaas2) {
      const params: HttpParams = new HttpParams().append(
        'domain',
        'devexchange'
      );
      url = endpoints.CONTENT_ENGINE_SEARCH;
      return this.httpClient
        .post(url, contentSelector, { params, headers })
        .pipe(
          timeout(timeouts.http),
          map(res => (res as any).documents[0]),
          map(document => {
            document.documentStructure.documentStructurePayload.entry.contentCollection.forEach(
              contentCollection => {
                contentCollection.sections.forEach(sectionData => {
                  const section = new Section();
                  if (sectionData.name.includes('Image')) {
                    section.headline = sectionData.items[0].name;
                    section.body = [
                      {
                        content:
                          environment.assetsCdn +
                          document.documentStructure.documentStructurePayload
                            .references[sectionData.items[0].value],
                      },
                    ];
                  } else {
                    section.headline = sectionData.items[0]?.value.default;
                    section.body = [
                      { content: sectionData.items[1]?.value.default },
                    ];
                  }
                  publicSectionData.push(section);
                });
              }
            );
            return publicSectionData;
          })
        );
    }

    return this.httpClient.get(url, { headers }).pipe(
      timeout(timeouts.http),
      map(res => (res as any).article),
      map(article => {
        publicSectionData = article[contentSelector].section;
        return publicSectionData;
      })
    );
  }
}
