import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BrowserStorage } from '../../models/browser-storage.enum';
import { FeedbackService } from '../../services/feedback.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss'],
})
export class UserFeedbackComponent implements OnInit {
  @Output() close: EventEmitter<void> = new EventEmitter();
  constructor(private feedbackService: FeedbackService) {}

  ngOnInit() {}

  closeModal() {
    this.close.emit();
    this.updateSessionStorage();
  }
  openFeedback() {
    this.closeModal();
    this.feedbackService.launchSurvey(
      environment.medallia.forms.userFeedbackSurvey
    );
  }

  private updateSessionStorage() {
    sessionStorage.setItem(BrowserStorage.SHOW_USER_FEEDBACK, 'false');
  }
}
