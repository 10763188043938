import { createSelector } from '@ngrx/store';

import * as fromDashboard from './dashboard.reducer';

export const selectDashboardState = state => state.dashboard;

export const apps = createSelector(
  selectDashboardState,
  fromDashboard.selectAllApps
);

export const appsLoaded = createSelector(
  selectDashboardState,
  dashboard => dashboard.apps.appsLoaded
);

export const selectAppOverviewMetricsByClientId = createSelector(
  selectDashboardState,
  (dashboard, props) => dashboard.overviewMetrics.entities[props.clientId]
);

export const registeredApp = createSelector(
  selectDashboardState,
  dashboard => dashboard.registerApp
);

export const environmentAvailable = createSelector(
  selectDashboardState,
  dashboard => dashboard.environmentAvailable
);
