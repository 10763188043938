import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tooltip',
  template: `
    <div
      class="tooltip"
      [ngStyle]="{ top: topPos, left: leftPos }"
      [innerHTML]="content"
    ></div>
  `,
  styleUrls: ['tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  @Input() content: string;
  @Input() ref: any;

  public topPos: string;
  public leftPos: string;

  ngOnInit() {
    const boundingBox = this.ref.getBoundingClientRect();
    this.topPos = boundingBox.top - 40 + window.scrollY + 'px';
    this.leftPos = boundingBox.left - 30 + window.scrollX + 'px';
  }
}
