import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/** Add headers related to message tracking. E.G. x-correlation-id & x-message-id */
@Injectable()
export class AcceptHeaderInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({
      headers: req.headers.set('Accept', 'application/json;v=1'),
    });
    return next.handle(clonedRequest);
  }
}
