import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  startWith,
  tap,
} from 'rxjs/operators';

import { Breadcrumb } from './breadcrumb.model';

@Component({
  selector: 'breadcrumbs',
  template: `
    <section>
      <ng-container *ngFor="let c of breadcrumb$ | async">
        <div class="breadcrumb">
          <a
            track-click
            eventName="dashboard-overview-page:{{ c.url }}"
            [routerLink]="c.url"
            class="link"
            >{{ c.name }}</a
          >
          <span class="caret">&#8250;</span>
        </div>
      </ng-container>
    </section>
  `,
  styleUrls: ['breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {}

  @Input() crumbs: Breadcrumb[];
  breadcrumb$: Observable<any>;

  ngOnInit() {
    this.crumbs = [];
    this.crumbs = this.buildBreadcrumbs(this.route.root);
    this.breadcrumb$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      distinctUntilChanged(),
      map(event => this.buildBreadcrumbs(this.route.root)),
      startWith(this.buildBreadcrumbs(this.route.root))
    );
  }

  private buildBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const label =
      route.routeConfig && route.routeConfig.data
        ? route.routeConfig.data['breadcrumb']
        : '';
    let path = route.routeConfig ? route.routeConfig.path : '';

    // If the path is coming from the route config and contains parameters, replace them as needed
    if (path.includes(':')) {
      // Split the path
      const pathArray = path.split('/');
      const finalPathArr = [];
      // For each section of the path, if it is a parameter, replace it with the appropriate param
      // Otherwise, keep as is.
      pathArray.forEach(fragment => {
        if (fragment.startsWith(':')) {
          fragment = fragment.replace(':', '');
          if (route.snapshot.params && route.snapshot.params[fragment]) {
            finalPathArr.push(route.snapshot.params[fragment]);
          }
        } else {
          finalPathArr.push(fragment);
        }
      });
      // Filter out undefined or empty strings and join into a working path again
      path = finalPathArr.filter(str => str).join('/');
    }

    if (url.includes('//')) {
      url = url.substr(0, url.length - 1);
    }
    let nextUrl;
    nextUrl = `${url}${path}/`;

    const breadcrumb: Breadcrumb = {
      name: label,
      url: nextUrl,
    };

    const newBreadcrumbs = breadcrumb.name
      ? [...breadcrumbs, breadcrumb]
      : breadcrumbs;

    if (route.firstChild) {
      return this.buildBreadcrumbs(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
}
