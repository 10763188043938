<section id="change-password">
  <a
    (click)="closeModal()"
    id="close-password-modal"
  >
    <img src="/assets/svg/close.svg" />
  </a>

  <h1 *ngIf="!showSuccessScreenFlag">Change Password</h1>
  <password-guidelines-tile [showGuidelines]="!showSuccessScreenFlag"></password-guidelines-tile>
  <form
    id="change-password-box"
    #changePasswordForm="ngForm"
    (ngSubmit)="changePassword()"
    *ngIf="!showSuccessScreenFlag"
  >
    <div
      id="error-message"
      class="error-message"
      [ngClass]="{ show: passwordChangeErrorVisible }"
    >
      {{ passwordChangeErrorMessage }}
    </div>
    <div class="form-group">
      <label>Old Password</label>
      <input
        type="password"
        id="currentPassword"
        name="currentPassword"
        [(ngModel)]="model.currentPassword"
        #currentPassword="ngModel"
        required
        [ngClass]="{
          error:
            (currentPassword.invalid &&
              (currentPassword.dirty || currentPassword.touched)) ||
            overallFormError
        }"
        (blur)="overallFormError = false"
        aria-label="Input Old Password Field"
      />

      <span
        id="currentPassword-error"
        class="error-message"
        [ngClass]="{
          show:
            currentPassword.invalid &&
            (currentPassword.dirty || currentPassword.touched)
        }"
        aria-label="Input Old Password Field"
      >This field is required.</span>
    </div>
    <div class="form-group">
      <label>New Password</label>
      <input
        type="password"
        name="newPassword"
        id="newPassword"
        [(ngModel)]="model.newPassword"
        #newPassword="ngModel"
        pattern="{{ passwordPattern }}"
        required
        [ngClass]="{
          error:
            (newPassword.invalid &&
              (newPassword.dirty || newPassword.touched)) ||
            overallFormError ||
            invalidPassword
        }"
        (blur)="overallFormError = false; checkInvalidPassword()"
        aria-label="Input New Password Field"
      />
      <span
        id="newPassword-error"
        class="error-message"
        [ngClass]="{
          show:
            invalidPassword ||
            (newPassword.invalid && (newPassword.dirty || newPassword.touched))
        }"
      >{{ invalidPasswordMessage }}</span>
    </div>
    <div class="form-group">
      <label>Retype New Password</label>
      <input
        type="password"
        name="verifyPassword"
        id="verifyPassword"
        [(ngModel)]="model.verifyPassword"
        #verifyPassword="ngModel"
        pattern="{{ passwordPattern }}"
        required
        [ngClass]="{
          error:
            (model.verifyPassword !== model.newPassword &&
              (verifyPassword.dirty || verifyPassword.touched)) ||
            overallFormError ||
            invalidPassword
        }"
        (blur)="overallFormError = false; checkInvalidPassword()"
        aria-label="Input Confirm New Password Field"
      />
      <span
        id="verifyPassword-error"
        class="error-message"
        [ngClass]="{
          show:
            model.verifyPassword !== model.newPassword &&
            (verifyPassword.dirty || verifyPassword.touched)
        }"
      >Passwords do not match.</span>
    </div>
    <div class="button-group">
      <button
        type="button"
        (click)="closeModal()"
        class="grey"
      >Cancel</button>
      <button
        id="change-pass-btn"
        type="submit"
        [disabled]="
          currentPassword.invalid ||
          invalidPassword ||
          newPassword.invalid ||
          model.verifyPassword != model.newPassword
        "
      >
        <span *ngIf="!loading">Reset</span>
        <spinner
          size="xxsmall"
          *ngIf="loading"
        ></spinner>
      </button>
    </div>
  </form>
  <div
    id="change-password-response"
    *ngIf="showSuccessScreenFlag"
  >
    <div id="success-image-div">
      <img
        src="/assets/svg/success-large.svg"
        alt="Success"
      />
    </div>
    <div id="success-div">Success!</div>
    <div id="success-message-div">
      Your password has been updated. For security purposes we will require you
      to reset your password again in {{ passwordExpiryPeriod }} days.
    </div>
  </div>
</section>