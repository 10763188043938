import { Directive } from '@angular/core';
import {
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  FormControl,
} from '@angular/forms';
@Directive({
  selector: '[notEmptyValidator]',
  // We add our directive to the list of existing validators
  providers: [
    { provide: NG_VALIDATORS, useExisting: NotEmptyValidator, multi: true },
  ],
})
export class NotEmptyValidator implements Validator {
  static validateNotEmpty(control: FormControl): ValidationErrors {
    // Here goes the validation code mentioned earlier
    let isEmptyVal = false;
    if (control.value && control.value.trim() === '') {
      isEmptyVal = true;
      return { isEmpty: true };
    }
    return null;
  }

  // Validate using custom function
  validate(c: FormControl): ValidationErrors | null {
    return NotEmptyValidator.validateNotEmpty(c);
  }
}
