import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { isNumber } from 'util';

@Component({
  selector: 'pagination',
  template: `
    <div id="pagination" *ngIf="lastPage > 1">
      <div
        class="pageChange"
        id="firstPageButton"
        aria-label="Go to First Page"
        tabindex="0"
        role="button"
        [ngStyle]="{ visibility: currentPage === 1 ? 'hidden' : null }"
        (click)="calculateRange(1)"
        (keydown.enter)="calculateRange(1)"
      >
        <img
          src="/assets/svg/left_triangle_with_line.svg"
          alt="First Page Button"
        />
      </div>
      <div
        class="pageChange"
        id="previousPageButton"
        aria-label="Go to Previous Page"
        tabindex="0"
        role="button"
        (click)="calculateRange(currentPage - 1)"
        (keydown.enter)="calculateRange(currentPage - 1)"
        [ngStyle]="{ visibility: currentPage === 1 ? 'hidden' : null }"
      >
        <img
          class="flip"
          src="/assets/svg/right_triangle.svg"
          alt="Previous Page Button"
        />
      </div>
      <div
        *ngFor="let page of pages"
        class="pageChange"
        [attr.tabindex]="page !== '...' ? 0 : -1"
        role="button"
        [attr.aria-label]="'Go to page' + page"
        [ngClass]="{ active: page === currentPage }"
        (click)="calculateRange(page)"
        (keydown.enter)="calculateRange(page)"
      >
        <span>{{ page }}</span>
      </div>
      <div
        class="pageChange"
        id="nextPageButton"
        aria-label="Go to Next Page"
        tabindex="0"
        role="button"
        [ngStyle]="{ visibility: currentPage === lastPage ? 'hidden' : null }"
        (click)="calculateRange(currentPage + 1)"
        (keydown.enter)="calculateRange(currentPage + 1)"
      >
        <img src="/assets/svg/right_triangle.svg" alt="Next Page Button" />
      </div>
      <div
        class="pageChange"
        id="lastPageButton"
        aria-label="Go to Last Page"
        tabindex="0"
        role="button"
        [ngStyle]="{ visibility: currentPage === lastPage ? 'hidden' : null }"
        (click)="calculateRange(lastPage)"
        (keydown.enter)="calculateRange(lastPage)"
      >
        <img
          class="flip"
          src="/assets/svg/left_triangle_with_line.svg"
          alt="Last Page Button"
        />
      </div>
    </div>
  `,
  styleUrls: ['pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() amount: number;
  @Input() max: number;

  @Output() updateRange: EventEmitter<{
    from: number;
    to: number;
  }> = new EventEmitter();

  public currentPage = 1;
  public from = 0;
  public to: number;
  public lastPage: number;
  public pages: any[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.amount.currentValue !== changes.amount.previousValue) {
      this.calculateLastPage();
      this.calculateRange();
    }
  }

  public calculateLastPage() {
    this.lastPage = Math.ceil(this.amount / this.max);
  }

  public calculateRange(pageToNavigateTo: number = 1) {
    if (typeof pageToNavigateTo !== 'number') return;
    pageToNavigateTo =
      pageToNavigateTo > this.lastPage ? this.lastPage : pageToNavigateTo;
    pageToNavigateTo = pageToNavigateTo < 1 ? 1 : pageToNavigateTo;

    this.currentPage = pageToNavigateTo;
    if (this.lastPage < 7) {
      // Display all pages
      this.pages = [1, 2, 3, 4, 5, 6].slice(0, this.lastPage);
    } else {
      // Display +-1 for context
      if (this.currentPage <= 3) {
        // No beginning ...
        this.pages = [1, 2, 3, 4, 5, '...', this.lastPage];
      } else if (this.currentPage > this.lastPage - 3) {
        // No end ...
        this.pages = [
          1,
          '...',
          this.lastPage - 4,
          this.lastPage - 3,
          this.lastPage - 2,
          this.lastPage - 1,
          this.lastPage,
        ];
      } else {
        // Both ...
        this.pages = [
          1,
          '...',
          this.currentPage - 1,
          this.currentPage,
          this.currentPage + 1,
          '...',
          this.lastPage,
        ];
      }
    }

    this.from = +(this.currentPage - 1) * +this.max;
    this.to = +this.from + +this.max;
    this.updateRange.emit({ from: this.from, to: this.to });
  }
}
