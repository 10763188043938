import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { environment } from '../../../environments/environment';

import {
  UserSettingsState,
  userSettingsReducer,
} from '../user-settings/user-settings.reducer';

export interface AppState {
  user_settings: UserSettingsState;
  router: RouterReducerState;
}

export const reducers: ActionReducerMap<AppState> = {
  user_settings: userSettingsReducer,
  router: routerReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];
