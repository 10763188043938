import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, zip } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { LoadApps } from 'src/app/modules/dashboard/dashboard.actions';
import { DashboardState } from 'src/app/modules/dashboard/dashboard.reducer';
import {
  apps,
  appsLoaded,
} from 'src/app/modules/dashboard/dashboard.selectors';
import { EnvironmentType } from 'src/app/shared/model/environment-type.enum';
import { SortPipe } from 'src/app/shared/pipes/sort.pipe';
import { AppsService } from 'src/app/shared/services/apps.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { App } from '../../models/app.model';
import { AppState } from '../../reducers';
import { Product } from '../../models/product.model';
import { ProductTypes } from '../../models/product-types.enum';
import { selectAuthenticationStatus } from 'src/app/store/authentication/authentication.selectors';

@Component({
  selector: 'get-access',
  templateUrl: 'get-access.component.html',
  styleUrls: ['get-access.component.scss'],
})
export class GetAccessComponent implements OnInit {
  @Input() data: Product;

  constructor(
    private store: Store<AppState>,
    private dashboardStore: Store<DashboardState>,
    private sortPipe: SortPipe,
    private appService: AppsService,
    private navService: NavigationService,
    private analyticsService: AnalyticsService
  ) {}

  // State variables
  requireAuthActive = false;
  registerAppActive = false;
  selectAppActive = false;
  successActive = false;
  loadingActive = false;
  errorActive = false;

  // App list
  apps$: Observable<any>;
  appsLoaded$;
  app: App;
  product: Product;

  // Products to connect before the named product can be connected
  prereqProdsToConnect: string[] = [];

  // Product or Products (in case of bundle) to connect
  prodsToConnect: string[] = [];

  // Error variables
  errorMessage = 'There was an error processing the request.';

  @Output() close: EventEmitter<void> = new EventEmitter();

  ngOnInit() {
    this.loadingActive = true;
    this.product = this.data;
    this.setProducts(this.product);
    this.store
      .pipe(select(selectAuthenticationStatus))
      .pipe(take(1))
      .subscribe(authenticated => {
        const formattedProductName = this.product.name
          .replace(/\s/g, '-')
          .toLowerCase();
        if (authenticated) {
          this.loadingActive = false;
          this.dashboardStore.dispatch(new LoadApps());
          this.activateLoading();
          this.apps$ = this.dashboardStore
            .pipe(select(apps))
            .pipe(filter((appList: App[]) => !!appList.length));
          this.apps$
            .pipe(take(1))
            .pipe(filter(appList => !!appList.length))
            .subscribe(appList => {
              appList = this.sortPipe.transform(
                appList,
                'ALPHABETICAL',
                'appName'
              );
              this.app = appList[0];
              this.activateSelectApp();
            });
          this.appsLoaded$ = this.store.pipe(select(appsLoaded));
          this.analyticsService.pubSubEvent({
            name: `form_start:${this.constructor.name}:${formattedProductName}-get-access-modal-authenticated`,
          });
        } else {
          this.activateRequireAuth();
          this.loadingActive = false;
          this.analyticsService.pubSubEvent({
            name: `form_start:${this.constructor.name}:${formattedProductName}-get-access-modal-unauthenticated`,
          });
        }
      });
  }

  public nav(url) {
    this.navService.navigateToUrl(url);
  }

  public convertToUrl(val: string) {
    return val
      .replace(/\s+/g, '-')
      .toLowerCase()
      .trim();
  }

  public setProducts(product: Product) {
    switch (product.productType) {
      case ProductTypes.API:
        // Get any pre-req products
        if (product.dependencyProducts) {
          product.dependencyProducts.forEach(prod => {
            this.prodsToConnect.push(prod);
          });
        }
        this.prodsToConnect.push(product.productId);
        break;
      case ProductTypes.BUNDLE:
        /**
         * A solution does not have a valid LE id, but is made up of several.
         * Connect only the real ids of the solution.
         */
        if (product.dependencyProducts) {
          product.dependencyProducts.forEach(prod => {
            this.prodsToConnect.push(prod);
          });
        }
        if (product.bundleProducts) {
          product.bundleProducts.forEach(prod => {
            this.prodsToConnect.push(prod);
          });
        }
        break;
      case ProductTypes.DEMO:
        break;
      default:
        if (product.dependencyProducts) {
          product.dependencyProducts.forEach(prod => {
            this.prodsToConnect.push(prod);
          });
        }
        this.prodsToConnect.push(product.productId);
        break;
    }
  }

  public connectApp() {
    this.activateLoading();
    const currentProduct = this.product.name.replace(/\s/g, '-').toLowerCase();
    this.analyticsService.pubSubEvent({
      name: `registration_attempt:${this.constructor.name}:${currentProduct}-connect`,
    });

    // Add prereqs to observable array
    const preReqs: Observable<any>[] = [];
    if (this.prereqProdsToConnect.length) {
      this.prereqProdsToConnect.forEach(productId => {
        preReqs.push(
          this.appService.addAppEnvConnectedProducts(
            this.app.appId,
            EnvironmentType.SANDBOX,
            productId
            // PCI doesn't exist in sandbox
          )
        );
      });
    }

    // Make calls to actual prods
    const prods: Observable<any>[] = [];
    if (this.prodsToConnect.length) {
      this.prodsToConnect.forEach(productId => {
        prods.push(
          this.appService.addAppEnvConnectedProducts(
            this.app.appId,
            EnvironmentType.SANDBOX,
            productId
          )
        );
      });
    }

    // If prereqs required, connect them - otherwise just connect the product
    if (preReqs && preReqs.length) {
      // Deconstruct the array and connect them
      zip(...preReqs).subscribe(
        vals => {
          // Connect products as pre-reqs are connected successfully
          zip(...prods).subscribe(
            prodVals => {
              this.activateSuccess();
            },
            err => {
              this.activateError('Error connecting the product.');
            }
          );
        },
        err => {
          this.activateError(
            "Error connecting the product's prerequisite products."
          );
        }
      );
    } else {
      zip(...prods).subscribe(
        vals => {
          this.activateSuccess();
        },
        err => {
          this.activateError('Error connecting the product.');
        }
      );
    }

    // There was a misconfiguration in CMS or product mappings.  Ensure correct Product type and ID.
    if (!(preReqs && preReqs.length) && !(prods && prods.length)) {
      this.activateError("There's nothing to connect!");
    }
  }

  private activateRequireAuth() {
    console.log('activateRequireAuth');
    this.requireAuthActive = true;
    this.registerAppActive = false;
    this.selectAppActive = false;
    this.successActive = false;
    this.errorActive = false;
    this.registerAppActive = false;
    this.loadingActive = false;
  }

  private activateSelectApp() {
    this.requireAuthActive = false;
    this.registerAppActive = false;
    this.selectAppActive = true;
    this.successActive = false;
    this.errorActive = false;
    this.loadingActive = false;
  }

  private activateSuccess() {
    // Track activate success
    const currentProduct = this.product.name.replace(/\s/g, '-').toLowerCase();
    this.analyticsService.pubSubEvent({
      name: `registration_complete:${this.constructor.name}:'${currentProduct}-connect`,
    });

    this.requireAuthActive = false;
    this.registerAppActive = false;
    this.selectAppActive = false;
    this.successActive = true;
    this.errorActive = false;
    this.loadingActive = false;
  }

  private activateError(msg?: string) {
    if (msg) {
      this.errorMessage = msg;
    } else {
      this.errorMessage = 'There was an error processing the request.';
    }
    this.requireAuthActive = false;
    this.registerAppActive = false;
    this.selectAppActive = false;
    this.successActive = false;
    this.errorActive = true;
    this.loadingActive = false;

    this.analyticsService.pubSubEvent({
      name: `registration_failure:${this.constructor.name}:error-${this.errorMessage}`,
    });
  }

  public activateLoading() {
    this.requireAuthActive = false;
    this.registerAppActive = false;
    this.selectAppActive = false;
    this.successActive = false;
    this.loadingActive = true;
    this.errorActive = false;
  }

  closeModal($event, appId?: string) {
    const currentProduct = this.product.name.replace(/\s/g, '-').toLowerCase();
    this.analyticsService.pubSubEvent({
      name: `form_field_interaction:${this.constructor.name}:close-modal-${currentProduct}`,
    });
    if (appId) {
      window.location.href = '/app-details/' + appId;
    }
    this.close.emit();
  }
}
