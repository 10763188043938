import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { App } from 'src/app/core/models/app.model';
import { StoreStates } from 'src/app/core/models/store-states.enum';

/* App Entities */
export function selectAppId(a: App): string {
  return a.appId;
}

export function sortByAppId(a: App, b: App): number {
  return a.appId.localeCompare(b.appId);
}

export const appsAdapter: EntityAdapter<App> = createEntityAdapter<App>({
  selectId: selectAppId,
  sortComparer: sortByAppId,
});

export interface AppState extends EntityState<App> {
  appsLoaded: StoreStates;
}
