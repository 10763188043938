import { Injectable, inject } from '@angular/core';
import { AxiosHeaders, AxiosRequestConfig } from 'axios';
import { AuthEngineEndpoints } from '../../shared/services/service-endpoints';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { CaliperX2Service } from '../../shared/services/caliperx2.service';
import { map, catchError } from 'rxjs/operators';
import { of, from } from 'rxjs';

@Injectable()
export class DashboardService {
  private caliperX2Service = inject(CaliperX2Service);
  private analyticsService = inject(AnalyticsService);
  private headers: AxiosHeaders = new AxiosHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json;v=1')
    .set('responseType', 'json');

  saveUserImage(image: string): any {
    const requestConfig: AxiosRequestConfig = {
      headers: this.headers,
      url: AuthEngineEndpoints.USER_PROFILE_PICTURE,
      method: 'POST',
      data: { image: image },
    };
    return from(this.caliperX2Service.gatewayRequest(requestConfig)).pipe(
      map(resp => resp.data as any),
      catchError(error => this.handleError(error, 'saveUserImage'))
    );
  }

  getUserImage(): any {
    const requestConfig: AxiosRequestConfig = {
      headers: this.headers,
      url: AuthEngineEndpoints.USER_PROFILE_PICTURE,
      method: 'GET',
    };
    return from(this.caliperX2Service.gatewayRequest(requestConfig)).pipe(
      map(resp => resp.data.image as any),
      catchError(error => this.handleError(error, 'getUserImage'))
    );
  }

  private handleError(error: any, methodName?: string) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.analyticsService.pubSubEvent({
      name: `application_submit_failure:${this.constructor.name}:error-${methodName}-user-portrait:${errorMessage}`,
    });
    return of({ status: 'ERROR', errorMessage: errorMessage });
  }
}
