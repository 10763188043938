import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import {
  AuthEngineEndpoints,
  AuthenticationEndpoints as endpoints,
} from './service-endpoints';
import { environment, timeouts } from '../../../environments/environment';
import { Router } from '@angular/router';
import { map, tap, timeout } from 'rxjs/operators';
import { LogoutAction } from '../../core/models/logout-action.enum';
import { StepUp } from '../../core/models/step-up.model';
import { StateService } from '../../core/services/state.service';

declare var collectDFP: any;

@Injectable()
export class AuthenticationService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private stateService: StateService
  ) {}

  private readonly CLIENT_ID = environment.authorization_client_id;

  public host(): string {
    if (environment.redirectMatch.test(window.location.origin)) {
      return window.location.origin;
    } else {
      return environment.host;
    }
  }

  /**
   * Method to get the authCorrelationId which is used in user authentication requests
   */
  async getAuthCorrelationId(state: string): Promise<Response> {
    const redirectUrl = endpoints.AUTH_REDIRECT_URL.replace(
      '%environmentHost%',
      this.host()
    );

    return await fetch(
      `${endpoints.AUTHID_RETRIEVAL_URL}?client_id=${this.CLIENT_ID}&redirect_uri=${redirectUrl}&response_type=code&scope=openid&prompt=login&state=${state}`
    );
  }

  /**
   * This method will attempt to authenticate the user and creating a new session for them.
   * It does nothing for failures.
   * @param username username entered on the /sign-in page
   * @param password password to the account
   */
  async authenticateUser(
    username: string,
    password: string
  ): Promise<Observable<any>> {
    let body = new HttpParams({
      fromObject: {
        grant_type: 'password',
        username: username,
        password: password,
      },
    }).append('deviceFingerprint', collectDFP());

    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.getAuthCorrelationId(this.stateService.getState()).then(res => {
      const authCorrelationId = res.url.split('=').pop();
      /**
       * Auth Correlation ID endpoint returns a redirect, but we're not using it.
       * We expect a 404 as a result, but we ignore it in order to use the auth correlation id query param.
       * However, there are cases where they may be an error, so we need to handle those separately.
       */
      if (res.status >= 400 && res.status !== 404) {
        return throwError(res);
      }
      return this.httpClient.post(
        `${AuthEngineEndpoints.AUTHENTICATION_URL}?authCorrelationId=${authCorrelationId}`,
        body.toString(),
        { headers }
      );
    });
  }

  /**
   * Calls the logout API to clear the authentication cookies
   * Or uses caliperx to do the same
   */
  public endSession(logoutAction?: LogoutAction): Observable<any> {
    if (logoutAction === LogoutAction.LOGOUT_CALIPERX) {
      // No need to continue to make caliperx calls, as they've already been made
      return of(null);
    }
    if (window['caliperx'].services.bootstrap) {
      window['caliperx'].services.logout();
    }

    const redirectUrl = endpoints.LOGOUT_REDIRECT_URL.replace(
      '%environmentHost%',
      this.host()
    );

    const options = { withCredentials: true };
    return this.httpClient
      .get(
        `${endpoints.LOGOUT_URL}?post_logout_redirect_uris=${redirectUrl}`,
        options
      )
      .pipe(
        tap(() => {
          // Logout
          if (logoutAction === LogoutAction.LOGOUT_ERROR) {
            this.router.navigate(['/error']);
            // Session timeout - will need to implement state in timeout timer
          } else {
            window.location.href = '/home/';
          }
        })
      );
  }

  /**
   * Generates a one-time pin for the user
   * @param token token received from challenge
   */
  public generateOTP(token: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    const options = { headers: headers, observe: 'response' as any };
    return this.httpClient
      .post(AuthEngineEndpoints.USER_OTP_GENERATE, token, options)
      .pipe(
        timeout(timeouts.http),
        map((data: HttpResponse<any>, error) => data.body),
        map(data => {
          return data;
        })
      );
  }

  /**
   * Validates the user's one time pin
   * @param stepUpData: the step up data that was returned by the initial challenge
   */
  public validateOTP(stepUpData: StepUp): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    const payload = {
      pin: stepUpData.pin,
      token: stepUpData.token,
      transactionInfo: stepUpData.transactionInfo,
    };

    const options = { headers: headers, observe: 'response' as any };
    return this.httpClient
      .post(AuthEngineEndpoints.USER_OTP_VALIDATE, payload, options)
      .pipe(
        timeout(timeouts.http),
        map((data: HttpResponse<any>, error) => data.body),
        map(data => {
          return data;
        })
      );
  }

  /* validate if the user is SSO Enabled */
  public isSSOEnabledDomain(email: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
    const options = { headers: headers, observe: 'response' as any };
    // make this call only when sign_302 is true
    const req = {
      email: email,
    };
    const resp = {
      email: email,
      data: { ssoEligible: false },
      ssoRedirect: null,
    };
    return this.httpClient
      .post(AuthEngineEndpoints.SSO_ELIGIBILITY_URL, req, options)
      .pipe(
        timeout(timeouts.http),
        map((httpResp: HttpResponse<typeof resp>) => {
          let resp = {
            data: httpResp.body,
            email: email,
            ssoRedirect: httpResp.headers.get('Location'),
          };
          return resp;
        })
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  public fetchSSOEnabledDomainList(): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
    return this.httpClient
      .get(AuthEngineEndpoints.RETRIEVE_SSO_ENABLED_DOMAIN_LIST)
      .pipe(
        timeout(timeouts.http),
        map((data: HttpResponse<any>) => {
          return data;
        })
      );
  }

  /* in case of sso login - redirect to gw to auto login */
  public ssoLogin(state: string, email: string, ssoRedirect: string) {
    const redirectUrl = endpoints.AUTH_REDIRECT_URL.replace(
      '%environmentHost%',
      this.host() //to redirect it to correct host on 302 of sign-in/result
    );

    // If there is no SSO Redirect URI, revert to legacy Ping redirect
    if (!ssoRedirect) {
      ssoRedirect = `${endpoints.AUTHID_RETRIEVAL_URL}?client_id=${this.CLIENT_ID}&redirect_uri=${redirectUrl}&response_type=code&scope=openid&prompt=login&login_hint=${email}`;
    }

    // Support local SSO authentication
    if (window.location.hostname === 'localhost') {
      ssoRedirect = `${endpoints.AUTHID_RETRIEVAL_URL}?client_id=${this.CLIENT_ID}&redirect_uri=${redirectUrl}&response_type=code&scope=openid&prompt=login&login_hint=localdeveloper@capitalone.com`;
    }

    // Add the state string to any redirect url
    window.location.href = `${ssoRedirect}&state=${state}`;
  }
}
