import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  HostListener,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { ModalService } from './modal.service';

@Component({
  selector: 'modal-container',
  template: `
    <section id="modal-container" [ngClass]="{ show: showModal }">
      <div id="entry" #entry></div>
    </section>
  `,
  styleUrls: ['modal-container.component.scss'],
})
export class ModalContainerComponent implements AfterViewInit {
  constructor(
    private modalService: ModalService,
    private cfr: ComponentFactoryResolver
  ) {}

  @ViewChild('entry', { read: ViewContainerRef })
  entry: ViewContainerRef;
  public showModal = false;

  // Allow for modal to be closed with escape key
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    evt: KeyboardEvent
  ) {
    this.closeModal();
  }

  ngAfterViewInit() {
    this.modalService.modal$.subscribe(component => {
      this.entry.clear();
      const factory = this.cfr.resolveComponentFactory(component.component);
      const m: any = this.entry.createComponent(factory);
      if (component.data) {
        m.instance.data = component.data;
      }
      m.instance.close.subscribe(() => this.closeModal());
      this.showModal = true;
    });
  }

  public closeModal() {
    this.entry.clear();
    this.showModal = false;
  }
}
