import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticationState } from './authentication.reducer';

export const selectAuthentication = createFeatureSelector('authentication');

export const selectAuthenticationStatus = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.authenticated
);

export const selectAuthenticationLoadedStatus = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.loaded
);

export const selectUser = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.user
);

export const selectEmail = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.user.email
);

export const selectStepUpData = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.stepUpData
);
