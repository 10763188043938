import { Component, EventEmitter, Output } from '@angular/core';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Component({
  selector: 'new-look',
  template: `
    <section id="new-look-modal">
      <div id="new-look-image">
        <img [src]="modalContent[modalState].image" alt="" />
        <span id="image-text">{{ modalContent[modalState].imageText }}</span>
      </div>
      <div id="new-look-content">
        <img
          id="close-modal"
          src="assets/svg/close_white.svg"
          (click)="close.emit()"
        />
        <div id="text-content">
          <h6>What's New</h6>
          <p [innerHTML]="modalContent[modalState].text"></p>
          <div id="action-container">
            <div id="dot-container">
              <span class="dot" [ngClass]="{ active: modalState === 0 }"></span>
              <span class="dot" [ngClass]="{ active: modalState === 1 }"></span>
              <span class="dot" [ngClass]="{ active: modalState === 2 }"></span>
              <span class="dot" [ngClass]="{ active: modalState === 3 }"></span>
            </div>
            <button
              class="white outline"
              *ngIf="modalState !== 0"
              (click)="changeScreen(-1)"
            >
              Previous
            </button>
            <button
              class="white"
              *ngIf="modalState !== 3"
              (click)="changeScreen(1)"
            >
              Next
            </button>
            <button
              class="white"
              *ngIf="modalState === 3"
              (click)="close.emit()"
            >
              Got It
            </button>
          </div>
        </div>
      </div>
    </section>
  `,
  styleUrls: ['new-look.component.scss'],
})
export class NewLookComponent {
  @Output() close: EventEmitter<void> = new EventEmitter();

  modalState = 0;
  modalContent = [
    {
      image: 'assets/img/new-look-0.svg',
      imageText: 'Products',
      text:
        '<span>Check out our expanding set of API solutions under the <b>Products</b> tab.</span>',
    },
    {
      image: 'assets/img/new-look-1.svg',
      imageText: 'Documentation',
      text:
        '<span>All of our product and platform-level documentation has been combined under the <b>Docs</b> tab for easier navigation.</span>',
    },
    {
      image: 'assets/img/new-look-2.svg',
      imageText: 'Resources',
      text:
        '<span>The <b>Resources</b> tab is now a hub for developer reference materials—like our Open Source projects.</span>',
    },
    {
      image: 'assets/img/new-look-3.svg',
      imageText: 'Sign Up/Sign In',
      text:
        '<span>The <b>Sign In button</b> now does double duty. You can sign up or sign in to your account from here to get access to your dashboard.</span>',
    },
  ];

  changeScreen(n: number) {
    this.modalState += n;
    this.modalState %= 4;
    this.modalState = this.modalState < 0 ? 0 : this.modalState;
  }
}
